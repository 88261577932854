// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_hidden(CHANGES) {
  // Setup...
  const { hidden } = CHANGES;
  this.logger("state-change", [`hidden: ${hidden}`], "stateChange");

  // Hide/show component el...
  this.element.setAttribute("data-is-hidden", hidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_hidden;
