// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_FrontPageScroller_scrollDirChange(ARGS) {
  // Setup...
  const { notificationStates } = this.state;
  const { direction, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Toggle visibility of notifications...
  const notificationStates_new = notificationStates.map((notifState) => {
    if (notifState.visibility === "hidden") return notifState;
    else return { ...notifState, visibility: direction === "down" ? "offscreen" : "visible" };
  });
  this.setState({ notificationStates: notificationStates_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
