// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * @description Function to handle changes to the mode state.
 *              The mode may be changed to one of the following possible states:
 *
 *              - "init"     → the component’s init. functions are about to be called
 *
 *              - "ready"    → all component’s init. functions have been called, but it is inactive
 *                           → interactions are disabled (no pointer events on entire component el.)
 *                           → scrolling is disabled (scroll instance is stopped)
 *
 *              - "active"   → the component is active;
 *                           → interactions are enabled
 *                           → scrolling is enabled
 *
 *              - "inactive" → interactions are disabled
 *                           → scroll is disabled
 *                           → logo animation is paused
 *
 *              The mode state changes at the occurence of the following events:
 *
 *              - "swup_link_click" → "inactive" when navigating to a fragment page,
 *                                  → "active" when navigating to the front page
 *
 * @param {Object} CHANGES - Object containing the changes to the mode state.
 **/

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  stateChange: (CHANGE) => [`mode:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_mode(CHANGES) {
  // Setup...
  const { mode } = CHANGES;
  const { lenis, logoAnimation } = this.modules;
  this.logger("state-change", loggerMessages.stateChange(mode), "default", { inline: true });

  // Toggle active attr. on component el...
  this.element.setAttribute("data-is-active", mode == "active");

  // Disable/enable scroll...
  const scrollInstance = lenis.instance;
  if (scrollInstance) {
    if (mode == "inactive") scrollInstance.stop();
    if (mode == "active") scrollInstance.start();
  }

  // Pause/unpause logo animation...
  const logoAnimationInstance = logoAnimation.instance;
  if (logoAnimationInstance) {
    if (mode == "inactive") logoAnimationInstance.timeline().pause();
    if (mode == "active") logoAnimationInstance.timeline().play();
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_mode;
