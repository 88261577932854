// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_fullscreenButton_click from "../eventHandlers/eh_fullscreenButton_click";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_fullscreenButton(BUTTONELEMENT = this.ref.fullscreenButton) {
  // Setup...
  this.logger("init", ["fullscreen button"], "action", { inline: true });

  // Stop, if no playbutton el. available
  if (!BUTTONELEMENT) {
    this.logger("info", ["no valid fullscreenButton element available"], "warning");
    return false;
  }

  // Listen for click & mouse interactions on fullscreen-button...
  BUTTONELEMENT.addEventListener("click", eh_fullscreenButton_click.bind(this));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_fullscreenButton;
