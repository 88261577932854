// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_contentWrapper_mouseLeaave from "../eventHandlers/eh_contentWrapper_mouseLeaave.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_content() {
  // Setup...
  const { contentWrapper, contentBg } = this.ref;
  this.logger("init", ["content elements/interaction"], "action", { inline: true });

  // Guard...
  if (
    !contentWrapper ||
    !contentBg ||
    contentWrapper instanceof HTMLElement === false ||
    contentBg instanceof HTMLElement === false
  ) {
    this.logger("error", ["init. content elements/interaction", "no valid DOM elements provided"], "error", { inline: true });
    return;
  }

  ////////////////////
  // Event handling //
  ////////////////////

  this.logger("init", ["content wrapper", "event handling"], "action", { inline: true });

  // Listen to mouse events on content wrapper...
  contentWrapper.addEventListener("mouseleave", eh_contentWrapper_mouseLeaave.bind(this));

  ///////////////////////////////////////////////////////////////////////////////
  // Setting of content backgr. width/height by measuring content wrapper size //
  ///////////////////////////////////////////////////////////////////////////////

  this.set_contentBgSize();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_content;
