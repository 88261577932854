// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_selfClick() {
  // Setup...
  const { initParentPageURL } = this.options;
  this.logger("event", ["fragm. page backgr. was clicked"], "event", { inline: true });

  // Notify other components/modules via eventbus...
  const args = { parentPageURL: initParentPageURL };
  eventbus.emit("FragmPageBg_selfClick", args);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_selfClick;
