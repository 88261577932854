// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_Nav_stCh_menuOpen(ARGS) {
  // Setup...
  const { menuOpen, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Set component mode to 'inactive' if menu is open...
  this.setState({ mode: menuOpen ? "inactive" : "active" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
