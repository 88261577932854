// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_card_mouseEnter from "../eventHandlers/eh_card_mouseEnter";
import eh_card_mouseLeave from "../eventHandlers/eh_card_mouseLeave";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_mouseInteraction() {
  // Setup...
  const refs = this.ref;
  this.logger("init", ["mouse interactions"], "action", { inline: true });

  // Init. mouse interaction w/ cards...
  const cards = refs.cards;
  cards.forEach((card) => {
    card.addEventListener("mouseenter", eh_card_mouseEnter.bind(this));
    card.addEventListener("mouseleave", eh_card_mouseLeave.bind(this));
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_mouseInteraction;
