// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../../../app/baseUtilities/validate/validate_refEl";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_simpleLangSwitch_click from "../eventHandlers/eh_simpleLangSwitch_click";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  noValidDOMEl: ["init. simple lang. switch", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_simpleLangSwitch() {
  // Setup...
  const { simpleLangSwitch } = this.ref;
  this.logger("init", ["simple lang. switch"], "action", { inline: true });

  // Guard...
  if (!validate_refEL(simpleLangSwitch)) {
    this.logger("error", loggerMsgs.noValidDOMEl, "error", { inline: true });
    return;
  }

  // Listen to clicks on simple lang. switch...
  simpleLangSwitch.addEventListener("click", eh_simpleLangSwitch_click.bind(this));

  // Set initial active- & hidden-state...
  const { simpleLangSwitchActive } = this.options;
  this.setState({
    simpleLangSwitchActive,
    simpleLangSwitchHidden: !simpleLangSwitchActive,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_simpleLangSwitch;
