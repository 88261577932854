// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import check_el_inView from "../../../../../app/baseUtilities/check/check_el_isInView";

function determ_lastLinkInView(LINKS) {
  if (!LINKS) return console.warn("determ_lastLinkInView(): No links provided.");
  let last_link_inView = null;
  LINKS.forEach((link) => {
    const { targetId } = link.dataset;
    const section = document.getElementById(targetId) || document.querySelector(`[data-scroll-id="${targetId}"]`);
    if (!section) return console.warn(`determ_lastLinkInView(): No section found with id: ${targetId}`);
    const is_inView = check_el_inView(section, { threshold: { top: 50, bottom: window.innerHeight / 2 } });
    if (is_inView) last_link_inView = { targetId, is_inView: true };
  });
  return last_link_inView;
}

function make_updtLinkStates(LINKS) {
  if (!LINKS) return console.warn("make_updtLinkStates(): No links provided.");
  const last_link_inView = determ_lastLinkInView(LINKS);
  const linkStates = LINKS.map((link) => {
    const { targetId } = link.dataset;
    const is_inView = last_link_inView ? targetId === last_link_inView.targetId : false;
    return { targetId, is_inView };
  });
  return linkStates;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_ScrollArea_scroll(ARGS) {
  // Setup...
  const { linkStates: linkStates_old, contentVisibility, is_mobile } = this.state;
  const { links } = this.ref;
  const { scrollProgress, is_scrolledTo_top, _name } = ARGS;
  // this.logger("eventbus call", [_name, ARGS], "event");

  // Updt. link states to highlight link corresponding to last section in view...
  if (linkStates_old) {
    const linkStates_new = make_updtLinkStates(links);
    const linkStates_haveChanged = JSON.stringify(linkStates_old) !== JSON.stringify(linkStates_new);
    if (linkStates_haveChanged) this.setState({ linkStates: linkStates_new });
  }

  // Set states...
  // ↳ at-top             → toggle menu font size
  // ↳ content visibility → hide content on scroll (mobile only)
  // ↳ scroll progress    → updt. width of progress indicator
  this.setState({
    scrolledToTop: is_scrolledTo_top,
    contentVisibility: is_mobile ? "hidden" : contentVisibility,
    scrollProgress: scrollProgress != undefined ? scrollProgress : this.state.scrollProgress,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
