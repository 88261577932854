// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_swup_page_load(ARGS) {
  // Setup...
  const { is_onParentPage } = this.options;
  const { fragmIsBeingOpened, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  // If comp. belongs to parent page, pause scrolling if fragm. page is being opened...
  const mode_new = is_onParentPage && fragmIsBeingOpened ? "inactive" : "active";
  this.setState({ mode: mode_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
