// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_FrontPageScroller_scrollDirChange(ARGS) {
  // Setup...
  const { simpleLangSwitchHidden, showSimpleLangContent } = this.state;
  const { direction, footerSection_is_inView, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  if (footerSection_is_inView) return;

  // Set states...
  // ↳ If not active (page is showing simple lang. content), hide simple lang. switch on down-scroll
  // ↳ Toggle visibility of back-to-top button
  const goingDown = direction === "down";
  this.setState({
    simpleLangSwitchHidden: !showSimpleLangContent && goingDown ? true : simpleLangSwitchHidden,
    backToTopButtonHidden: goingDown ? false : true,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
