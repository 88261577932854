// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_closeAnimDirection(CHANGES) {
  // Setup...
  const { closeAnimDirection } = CHANGES;
  this.logger("state-change", [`closeAnimDirection: ${closeAnimDirection}`], "default", { inline: true });

  // Set related comp. element attribute...
  this.element.setAttribute("data-close-anim-dir", closeAnimDirection);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_closeAnimDirection;
