// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../../../app/baseUtilities/updt/updt_styleProp.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_scrollProgress(CHANGES) {
  // Setup...
  const { scrollProgressIndicator } = this.ref;
  const { scrollProgress } = CHANGES;
  this.logger("state-change", [`scroll progress: ${scrollProgress}`], "default", { inline: true });

  // Updt. progress var. on indicator el.
  if (!this.validate_refEl(scrollProgressIndicator)) return;
  updt_styleProp(scrollProgressIndicator, "--progress", `${scrollProgress * 100}%`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
