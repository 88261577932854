// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { Loader } from "@googlemaps/js-api-loader";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../../../app/baseUtilities/validate/validate_refEl.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. loader"],
  initSuccess: ["init. loader", "init. success"],
  noValidDOMEl: ["init. loader", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_loader() {
  // Setup...
  const { apiKey } = this.options;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!apiKey || typeof apiKey !== "string" || apiKey.length < 1) this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Create loader instance...
  const loader = new Loader({ apiKey: apiKey, version: "weekly", libraries: ["places"] });

  // Load and initialize map...
  loader.load().then((googleClient) => {
    if (!googleClient) return;
    this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
    this.init_map(googleClient);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
