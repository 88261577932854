// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_contentVisibility(CHANGES) {
  // Setup...
  const { openButton, contentWrapper, contentBg } = this.ref;
  const { contentVisibility } = CHANGES;
  this.logger("state-change", [`content visibility: ${contentVisibility}`], "default", { inline: true });

  // Hide/show content...
  openButton.setAttribute("data-is-hidden", contentVisibility === "visible");
  contentWrapper.setAttribute("data-is-hidden", contentVisibility === "hidden");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
