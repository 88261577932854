// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_menuOpen(CHANGES) {
  // Setup...
  const { is_mobile } = this.state;
  const { menu, menuButton } = this.ref;
  const { menuOpen } = CHANGES;
  this.logger("state-change", [`menuOpen: ${menuOpen}`], "default", { inline: true });

  // Show/hide menu element...
  menu.setAttribute("data-is-open", menuOpen);

  // On mobile...
  // ↳ show/hide menu button...
  // ↳ hide back-to-top/close-fragm.-page button (if menu is open)...
  if (is_mobile) {
    menuButton.setAttribute("data-is-hidden", menuOpen);
    if (menuOpen) this.setState({ backToTopButtonHidden: true, fragmPageCloseButtonHidden: true });
  }

  // Notify other components...
  // ↳ Frontpage Scroller → pause scroll events while menu is open (mobile only)...
  if (is_mobile) eventbus.emit("Nav_stCh_menuOpen", { menuOpen });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_menuOpen;
