// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_completed(CHANGES) {
  // Setup...
  const { tolog_statechanges } = this.options;
  const { completed } = CHANGES;
  if (tolog_statechanges.find((e) => e.name === "completed")?.log === "true")
    this.logger("state-change", [`completed: ${completed}`], "default", { inline: true });

  // Hide component (reveal page)...
  this.setState({ hidden: true });

  // Notify other components...
  eventbus.emit("Preloader_completed");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_completed;
