// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_caption(CHANGES) {
  // Setup...
  const { captionDisplay } = this.ref;
  const { caption } = CHANGES;
  this.logger("state-change", [`caption: ${caption}`], "default", { inline: true });

  // Hide/show caption display...
  captionDisplay.setAttribute("data-is-hidden", caption === "");

  // Updt. caption display text...
  captionDisplay.querySelector("span").textContent = caption;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
