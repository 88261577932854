// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_fragmPageCloseButtonHidden(CHANGES) {
  // Setup...
  const { fragmPageCloseButton } = this.ref;
  const { fragmPageCloseButtonHidden } = CHANGES;
  this.logger("state-change", [`fragm.-page-close-button hidden: ${fragmPageCloseButtonHidden}`], "default", { inline: true });

  // Hide/show fragm. page close-button element...
  fragmPageCloseButton.setAttribute("data-is-hidden", fragmPageCloseButtonHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_fragmPageCloseButtonHidden;
