// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_pausing() {
  // Setup...
  const { playback } = this.state;
  this.logger("event", ["pausing"], "event", { inline: true });

  // Ensure comp. playback-state is set to "pause"...
  // (This is necessary because the video-js instance may have been paused via it’s native controls
  // and not via the comp. custom controls, in which case the comp. playback-state will be out of sync
  // with the native instance state.)
  if (playback !== "pause") this.setState({ playback: "pause" });

  // Updt. is-playing attr.
  this.element.setAttribute("data-is-playing", false);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_pausing;
