// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mode(CHANGES) {
  // Setup...
  const { mode } = CHANGES;
  const compEl = this.element;
  this.logger("state-change", [`mode: ${mode}`], "default", { inline: true });

  // Set is-active attr. to toggle visibility...
  if (mode === "inactive") compEl.setAttribute("data-is-active", "false");
  else compEl.setAttribute("data-is-active", "true");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
