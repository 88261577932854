// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_change(EVENTDATA) {
  // Setup...
  const flickityInstance = this.modules.flickity.instance;
  this.logger("event", ["slide change occured"], "event", { inline: true });

  // Updt. states...
  // ↳ caption
  // ↳ selected-slide-index
  const selectedSlide = flickityInstance.selectedElement;
  const caption = selectedSlide.hasAttribute("data-caption") ? selectedSlide.dataset.caption : "";
  const selectedSlideIndex = flickityInstance.selectedIndex + 1;
  this.setState({ caption, selectedSlideIndex });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_change;
