// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_acceptButton_click() {
  // Setup...
  this.logger("info", ["event", "accept-button clicked"], "event", { inline: true });

  // Save cookie & hide cookie banner...
  this.saveCookie(30);
  this.setState({ hidden: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_acceptButton_click;
