// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_card_mouseEnter(EVENTDATA) {
  // Setup...
  if (this.state.is_mobile) return;
  this.logger("event", ["mouse entered section card"], "event", { inline: true });

  // Check card type to determine interaction...
  const card = EVENTDATA.target;
  const { type: cardType, id: cardId } = card.dataset;
  eventbus.emit("FrontPageScroller_card_mouseEnter", { cardType, cardId });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_card_mouseEnter;
