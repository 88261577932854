// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function check_el_isInView(
  EL,
  CONFIG = {
    threshold: { top: 50, bottom: 50 },
  }
) {
  const { threshold } = CONFIG;
  const { top, bottom } = EL.getBoundingClientRect();
  const is_inView = top < window.innerHeight - threshold.bottom && bottom > 0 + threshold.top;
  return is_inView;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
