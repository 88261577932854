// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import check_el_isInView from "../../../../../app/baseUtilities/check/check_el_isInView";

function compare_cardStates(cardStates_old, cardStates_new) {
  const cardStatesHaveChanged = cardStates_new.some((cardState_new, i) => {
    const { is_inView } = cardState_new;
    const { is_inView: is_inView_old } = cardStates_old[i];
    return is_inView !== is_inView_old;
  });
  return cardStatesHaveChanged;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Variables
let direction_prev = 0;
let footerSection_is_inView_prev = false;

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_scroll(EVENTDATA) {
  // Setup...
  const { footerSection } = this.ref;
  const { cardStates } = this.state;
  const { direction } = EVENTDATA;
  const inViewThreshold = 50;
  const inViewCheckConfig = { threshold: { top: inViewThreshold, bottom: inViewThreshold } };
  this.logger("event", ["lenis instance scroll"], "event", { inline: true });

  // // Get scroll position...
  // const scrollPos_curr = this.element.scrollTop;
  // const scrollPos_max = this.element.scrollHeight - this.element.clientHeight;

  ////////////////////////////////////////////////////
  // Footer section in-view check & change handling //
  ////////////////////////////////////////////////////

  const footerSection_is_inView = footerSection ? check_el_isInView(footerSection, inViewCheckConfig) : false;

  if (footerSection) {
    // When footer comes in/out of view, notify other components...
    // ↳ Nav → Toggle visibility of back-to-top button & media toggle
    if (footerSection_is_inView !== footerSection_is_inView_prev) {
      eventbus.emit("FrontPageScroller_footerSectionInViewChange", { footerSection_is_inView });
      footerSection_is_inView_prev = footerSection_is_inView;
    }
  }

  ////////////////////////
  // Card states update //
  ////////////////////////

  // Create updt. card states...
  const cardStates_new = cardStates.map((cardState) => {
    const { el } = cardState;
    const is_inView = check_el_isInView(el, inViewCheckConfig);
    return { el, is_inView };
  });
  // Check if card states have changed...
  const cardStatesHaveChanged = compare_cardStates(cardStates, cardStates_new);
  if (cardStatesHaveChanged) this.setState({ cardStates: cardStates_new });

  ////////////////////
  // Eventbus calls //
  ////////////////////

  // Check if scroll direction has changed & update direction var...
  const directionHasChanged = direction !== direction_prev;
  direction_prev = direction;

  // Notify other components of scroll direction change...
  // ↳ Nav → Toggle visibility of back-to-top button & simple lang. switch
  if (directionHasChanged) {
    const args = { direction: direction === 1 ? "down" : "up", footerSection_is_inView };
    eventbus.emit("FrontPageScroller_scrollDirChange", args);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
