// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../../../app/baseUtilities/validate/validate_refEl.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_eventLink_mouseEnter from "../eventHandlers/eh_eventLink_mouseEnter.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. event-links"],
  initSuccess: ["init. event-links", "init. success"],
  noValidDOMEl: ["init. event-links", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_eventLinks() {
  // Setup...
  const { eventLinks } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (eventLinks.every((el) => !validate_refEL(el))) this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to mouse events on event links...
  eventLinks.forEach((el) => el.addEventListener("mouseenter", eh_eventLink_mouseEnter.bind(this, el)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
