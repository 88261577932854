// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_error(EVEENTDATA) {
  // Setup...
  const videoJsInstance = this.modules.videojs.instance;
  this.logger("event", ["error"], "event", { inline: true });

  // Log error...
  this.logger("error", [EVEENTDATA], "error");

  // Retry loading source...
  this.logger("info", ["retrying to load source"], "action", { inline: true });
  videoJsInstance.src({ src: this.state.source, type: "video/mp4" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_error;
