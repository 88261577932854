// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_fileLoad from "../eventHandlers/eh_fileLoad";
import eh_progress from "../eventHandlers/eh_progress";
import eh_complete from "../eventHandlers/eh_complete";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_loader() {
  // Setup...
  this.logger("init", ["loader"], "action", { inline: true });

  // Create preloadJs queue instance...
  const loadQueue = new createjs.LoadQueue(true);

  // Config. instance events...
  loadQueue.on("fileload", eh_fileLoad.bind(this), this);
  loadQueue.on("progress", eh_progress.bind(this), this);
  loadQueue.on("complete", eh_complete.bind(this), this);

  return loadQueue;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_loader;
