// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_ScrollArea_scroll(ARGS) {
  // Setup...
  const { is_scrolledTo_bottom, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Set close-animation direction...
  this.setState({ closeAnimDirection: is_scrolledTo_bottom ? "up" : "down" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_ScrollArea_scroll;
