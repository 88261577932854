// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../../../app/baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_height(CHANGES) {
  // Setup...
  const { height } = CHANGES;
  this.logger("state-change", [`height: ${height}`], "default", { inline: true });

  // Set component element height...
  updt_styleProp(this.element, "--screenHeight", `${height}px`);
  updt_styleProp(this.element, "--h", `${height}px`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_height;
