// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_ticketLink_click({ target }, EVENTDATA) {
  // Setup...
  EVENTDATA.stopPropagation();
  EVENTDATA.preventDefault();
  this.logger("event", ["ticket link clicked"], "event", { inline: true });

  // Open link target in new tab...
  if (!target) return;
  window.open(target, "_blank");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_ticketLink_click;
