// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_ticketLink_click from "../eventHandlers/eh_ticketLink_click";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_ticketLinks() {
  // Setup...
  const refs = this.ref;
  this.logger("init", ["ticket links"], "action");

  /////////////////////////////////////////////////////////////////////
  // Listen to clicks on ticket links to open link target in new tab //
  /////////////////////////////////////////////////////////////////////

  const { ticketLinks } = refs;
  ticketLinks.forEach((link) => link.addEventListener("click", eh_ticketLink_click.bind(this, { target: link.dataset.target })));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_ticketLinks;
