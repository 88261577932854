// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_logo_mouseEnter from "../eventHandlers/eh_logo_mouseEnter.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_logo() {
  // Setup...
  const { logo } = this.ref;
  this.logger("init", ["logo"], "action", { inline: true });

  // Guard...
  if (!logo || logo instanceof HTMLElement === false) {
    this.logger("error", ["init. logo", "no valid DOM element provided"], "error", { inline: true });
    return;
  }

  // Listen to mouse events on logo element...
  this.logger("init", ["logo", "mouse event handling"], "action", { inline: true });
  logo.addEventListener("mouseenter", eh_logo_mouseEnter.bind(this));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_logo;
