// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_playing() {
  // Setup...
  const compEl = this.element;
  const { playback } = this.state;
  const { has_loadingCover } = this.options;
  this.logger("event", ["playing"], "event", { inline: true });

  // Ensure comp. playback-state is set to "playing"...
  // (This is necessary because the video-js instance may have been played via it’s native controls
  // and not via the comp. custom controls, in which case the comp. playback-state will be out of sync
  // with the native instance state.)
  if (playback !== "play") this.setState({ playback: "play" });

  // Updt. is-playing & first-playback-occured attr...
  compEl.setAttribute("data-is-playing", true);

  // Hide loading cover...
  if (has_loadingCover) this.setState({ loadingCover: { visibility: "hidden" } });

  // Updt. first-playback-occured attr...
  if (compEl.dataset.firstPlaybackOccured != "false") return;
  this.logger("event", ["first playback occured"], "event", { inline: true });
  compEl.dataset.firstPlaybackOccured = "true";
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_playing;
