// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_scrolledToTop(CHANGES) {
  if (this.state.is_mobile) return;

  // Setup...
  const { scrolledToTop } = CHANGES;
  this.logger("state-change", [`is scrolled to top: ${scrolledToTop}`], "default", { inline: true });

  // Toggle menu font size (at-top attr.)...
  this.element.setAttribute("data-is-at-top", scrolledToTop);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
