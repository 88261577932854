// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function unskewLetters(TEXTELEMENT) {
  if (!TEXTELEMENT || TEXTELEMENT instanceof HTMLElement === false) {
    this.logger("error", ["skewLetters", "no valid DOM element provided"], "error", { inline: true });
    return;
  }

  // Skew letter elements inside text element by random degree...
  const letters = [...TEXTELEMENT.querySelectorAll("span:not([data-rzt-text-to-skew-word-wrapper])")];
  for (let l of letters) updt_styleProp(l, "--skew", `0deg`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default unskewLetters;
