// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  srcAlreadyInit: ["source already initialised"],
  srcInvalid: ["source is invalid"],
  initSrc: (initSourceType, initSource) => [
    "setting init. source",
    `init. source type: ${initSourceType}`,
    `init. source: ${initSource}`,
  ],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_source() {
  // Setup...
  const { is_mobile, sourceInitialised } = this.state;
  const { has_teaser, has_teaser_mobile, has_fullVideo_mobile, src_teaser_mobile, src_full_mobile, src_teaser, src_full } =
    this.options;

  //////////////////////
  // Set init. source //
  //////////////////////

  // Stop if source already initialised...
  if (sourceInitialised) {
    this.logger("init", loggerMessages.srcAlreadyInit, "warning", { inlined: true });
    return;
  }

  // Determ. init. source type (based on available sources)...
  const initSourceType =
    is_mobile && has_teaser_mobile
      ? "teaser_mobile"
      : is_mobile && has_fullVideo_mobile
      ? "full_mobile"
      : has_teaser
      ? "teaser"
      : "full";

  // Determ. init. source (based on init. source type & whether on mobile screen)...
  const initSource =
    is_mobile && has_teaser_mobile
      ? src_teaser_mobile
      : is_mobile && has_fullVideo_mobile
      ? src_full_mobile
      : has_teaser
      ? src_teaser
      : src_full;

  // Stop, if init. source is invalid (null, undefined or empty string)...
  if (!initSource || initSource === null || initSource === undefined || initSource === "" || typeof initSource !== "string") {
    this.logger("init", loggerMessages.srcInvalid, "error", { inline: true });
    return;
  }

  this.logger("init", loggerMessages.initSrc(initSourceType, initSource), "action");
  this.setState({
    sourceType: initSourceType,
    source: initSource,
    sourceInitialised: true,
    sourceWasUpdated: true,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_source;
