// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_FrontPageScroller_footerSectionInViewChange(ARGS) {
  // Setup...
  const { backToTopButtonHidden } = this.state;
  const { footerSection_is_inView, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Set states...
  // ↳ Hide back-to-top button
  // ↳ Toggle visibility of media toggle
  this.setState({
    backToTopButtonHidden: footerSection_is_inView ? true : backToTopButtonHidden,
    mediaToggleState: { is_active: footerSection_is_inView ? false : true },
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
