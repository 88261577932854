// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import get_elementStyleValue from "../../../../../app/baseUtilities/get/get_elementStyleValue";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_menuSize() {
  // Setup...
  const { is_mobile } = this.state;
  const { menu, menuLinkWrapper, menuLinks } = this.ref;

  // On mobile, set menu size to screen size.
  if (is_mobile) {
    this.setState({
      menuSize: {
        w: window.innerWidth,
        h: window.innerHeight,
      },
    });
  }
  // On desktop, set menu size to widest menu link & link wrapper height.
  else {
    // Guard...
    if (!menu || !menuLinkWrapper || !menuLinks) {
      this.logger("error", ["set_menuSize", "no valid DOM elements found"], "error", { inline: true });
      return;
    }

    // Determ. menu width by measuring widest menu link...
    const widestMenuLink = menuLinks.reduce(
      (acc, menuLink) => {
        const linkTextElement = menuLink.querySelector("span");
        const { width } = linkTextElement.getBoundingClientRect();
        return width > acc.width ? { width, menuLink } : acc;
      },
      { width: 0, menuLink: null }
    );

    // Determ. menu height by measuring menu link wrapper...
    const { height: linkWrapperHeight } = menuLinkWrapper?.getBoundingClientRect();

    // Get menu padding...
    const menuPadding = {
      t: parseFloat(get_elementStyleValue(menu, "padding-top")),
      r: parseFloat(get_elementStyleValue(menu, "padding-right")),
      b: parseFloat(get_elementStyleValue(menu, "padding-bottom")),
      l: parseFloat(get_elementStyleValue(menu, "padding-left")),
    };

    // Record menu size...
    this.setState({
      menuSize: {
        w: widestMenuLink.width + menuPadding.r + menuPadding.l,
        h: linkWrapperHeight + menuPadding.t + menuPadding.b,
      },
    });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
