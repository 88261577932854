// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_source(CHANGES) {
  // Setup...
  const { source } = CHANGES;
  this.logger("state-change", [`new source: ${source}`], "default", { inline: true });

  // Updt. video-js instance src...
  this.logger("info", [`Updating videoJs instance src.`], "action");
  const playerInstance = this.modules.videojs.instance;
  playerInstance.src({ src: source, type: "video/mp4" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_source;
