// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_muted(CHANGES) {
  // Setup...
  const playerInstance = this.modules.videojs.instance;
  const { muted } = CHANGES;
  this.logger("state-change", [`muted: ${muted}`], "default", { inline: true });

  // Mute/Unmute video-js instance...
  playerInstance.muted(muted);

  // Updt. attr. of comp. el...
  this.element.setAttribute("data-is-muted", muted);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_muted;
