// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function check_el_isInActiveRange(EL, ACTIVERANGE = { start: 0, end: window.innerHeight }) {
  if (EL instanceof HTMLElement === false) console.warn("no valid DOM element passed to check_elementIsInActiveRange()");
  const elDOMrect = EL.getBoundingClientRect();
  const { top: elTop, bottom: elBottom } = elDOMrect;
  const isInActiveRange = elTop <= ACTIVERANGE.end && elBottom >= ACTIVERANGE.start;
  return isInActiveRange;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

const activeRange = { start: 50, end: window.innerHeight / 2 };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_ScrollArea_scroll(ARGS) {
  // Setup...
  const { monthHeadings } = this.ref;
  const { is_scrolledTo_top, _name } = ARGS;
  // this.logger("eventbus call", [_name, ARGS], "event");

  // Updt. active month (month heading el. scrolled into view)...
  let activeMonthIndex;
  if (is_scrolledTo_top) activeMonthIndex = 0; // ← at top, first month is active
  else {
    monthHeadings.some((h) => {
      const is_inActiveRange = check_el_isInActiveRange(h, activeRange);
      if (is_inActiveRange) {
        activeMonthIndex = parseInt(h.dataset.index);
        return true;
      }
    });
  }

  this.setState({ activeMonth: activeMonthIndex });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
