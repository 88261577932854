// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../../../app/baseUtilities/validate/validate_refEl";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_button_click from "../eventHandlers/eh_button_click";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. buttons"],
  initSuccess: ["init. buttons", "init. success"],
  noValidDOMEl: ["init. buttons", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_buttons() {
  // Setup...
  const { buttons } = this.ref;
  this.logger("init", ["buttons"], "action", { inline: true });

  // Guard...
  if (!buttons || buttons.every((btn) => validate_refEL(btn) === false)) this.cancel_featureInit();

  // Listen to click-event on buttons...
  buttons.forEach((btn) => btn.addEventListener("click", eh_button_click.bind(this, btn)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "default", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_buttons;
