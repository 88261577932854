// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_hidden(CHANGES) {
  // Setup...
  const { tolog_statechanges, pagerevealdelay } = this.options;
  const { hidden } = CHANGES;
  if (tolog_statechanges.find((e) => e.name === "hidden")?.log === "true")
    this.logger("state-change", [`hidden: ${hidden}`], "default", { inline: true });

  // Show/hide component element...
  setTimeout(() => this.element.setAttribute("data-is-hidden", hidden), parseInt(pagerevealdelay));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_hidden;
