// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_lenis from "./init/init_lenis";
import init_cards from "./init/init_cards.js";
import init_mouseInteraction from "./init/init_mouseInteraction.js";
import init_logoAnimation from "./init/init_logoAnimation.js";
import init_ticketLinks from "./init/init_ticketLinks";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_Preloader_completed from "./eventbusHandlers/ebh_Preloader_completed.js";
import ebh_swup_link_click from "./eventbusHandlers/ebh_swup_link_click.js";
import ebh_swup_history_popstate from "./eventbusHandlers/ebh_swup_history_popstate.js";
import ebh_FragmPageBg_selfClick from "./eventbusHandlers/ebh_FragmPageBg_selfClick.js";
import ebh_backToTopButton_click from "./eventbusHandlers/ebh_backToTopButton_click.js";
import ebh_Nav_stCh_menuOpen from "./eventbusHandlers/ebh_Nav_stCh_menuOpen.js";

import stChH_height from "./stateChangeHandlers/stChH_height.js";
import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_cardStates from "./stateChangeHandlers/stChH_cardStates.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class FrontPageScroller extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      scrollArea: null,
      scrollAreaContent: null,
      scrollContent: null,
      sections: [],
      footerSection: null,
      cards: [],
      ticketLinks: [],
      logoWrapper: null,
      logoSVG: null,
      logoShape: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "FrontPageScroller",
      run_withLogs: "defaultValue",
      logStyles: "defaultValue",
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_lenis = init_lenis.bind(this);
    this.init_cards = init_cards.bind(this);
    this.init_mouseInteraction = init_mouseInteraction.bind(this);
    this.init_logoAnimation = init_logoAnimation.bind(this);
    this.init_ticketLinks = init_ticketLinks.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      lenis: { instance: this.init_lenis() },
      logoAnimation: { instance: this.init_logoAnimation() },
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);
    this.ebl_Preloader_completed = ebh_Preloader_completed.bind(this);
    this.ebl_swup_link_click = ebh_swup_link_click.bind(this);
    this.ebl_swup_history_popstate = ebh_swup_history_popstate.bind(this);
    this.ebl_FragmPageBg_selfClick = ebh_FragmPageBg_selfClick.bind(this);
    this.ebl_backToTopButton_click = ebh_backToTopButton_click.bind(this);
    this.ebl_Nav_stCh_menuOpen = ebh_Nav_stCh_menuOpen.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_height = stChH_height.bind(this);
    this.stChL_mode = stChH_mode.bind(this);
    this.stChL_cardStates = stChH_cardStates.bind(this);

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    // Destroy scroll instance...
    this.modules.lenis.instance.destroy();

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("Preloader_completed", this.ebl_Preloader_completed);
    eventbus.off("swup_link_click", this.ebl_swup_link_click);
    eventbus.off("swup_history_popstate", this.ebl_swup_history_popstate);
    eventbus.off("FragmPageBg_selfClick", this.ebl_FragmPageBg_selfClick);
    eventbus.off("backToTopButton_click", this.ebl_backToTopButton_click);
    eventbus.off("Nav_stCh_menuOpen", this.ebl_Nav_stCh_menuOpen);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_cards();
    this.init_states();
    this.init_mouseInteraction();
    this.init_ticketLinks();
    this.init_eventbus();
    // this.setState({ mode: "active" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({
      height: window.innerHeight,
      is_mobile: window.innerWidth <= 640,
      footerSectionState: { is_inView: false },
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("Preloader_completed", this.ebl_Preloader_completed);
    eventbus.on("swup_link_click", this.ebl_swup_link_click);
    eventbus.on("swup_history_popstate", this.ebl_swup_history_popstate);
    eventbus.on("FragmPageBg_selfClick", this.ebl_FragmPageBg_selfClick);
    eventbus.on("backToTopButton_click", this.ebl_backToTopButton_click);
    eventbus.on("Nav_stCh_menuOpen", this.ebl_Nav_stCh_menuOpen);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    ////////////// height //////////////
    ////////////////////////////////////

    if ("height" in CHANGES) this.stChL_height(CHANGES);

    /////////////// mode ///////////////
    ////////////////////////////////////

    if ("mode" in CHANGES) this.stChL_mode(CHANGES);

    //////////// cardStates ////////////
    ////////////////////////////////////

    if ("cardStates" in CHANGES) this.stChL_cardStates(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default FrontPageScroller;
