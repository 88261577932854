// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_ScrollArea_reachedTop(ARGS) {
  // Setup...
  const { simpleLangSwitchActive } = this.state;
  const { _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Set states...
  // ↳ If enabled, show simple lang. switch
  if (simpleLangSwitchActive) this.setState({ simpleLangSwitchHidden: false });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
