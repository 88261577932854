// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_backToTopButton_click(ARGS) {
  // Setup...
  const { lenis } = this.modules;
  const { _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Scroll lenis instance to top...
  const scrollInstance = lenis?.instance;
  if (scrollInstance) scrollInstance.scrollTo(0, 0);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_backToTopButton_click;
