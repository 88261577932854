// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import get_elementStyleValue from "../../../../../app/baseUtilities/get/get_elementStyleValue";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_closeButton_click from "../eventHandlers/eh_closeButton_click.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_notifications() {
  // Setup...
  const { is_mobile } = this.state;
  const { notifications } = this.ref;
  this.logger("init", ["notifications"], "action", { inline: true });

  // Guard...
  if (!notifications.every((notif) => notif && notif instanceof HTMLElement === true)) {
    this.logger("error", ["init. notifications", "no valid DOM elements provided"], "error", { inline: true });
    return;
  }

  // Set notification width (desktop only)...
  if (!is_mobile) {
    const menuEl = document.querySelector("[g-ref='Nav:menu']");
    const menuWidth = menuEl ? parseFloat(get_elementStyleValue(menuEl, "--w")) - 8 : "auto";
    this.setState({ notificationWidth: menuWidth === "auto" ? "auto" : `${menuWidth}px` });
  }

  // Set notification positions...
  const originEl = is_mobile ? document.querySelector("[g-ref='Nav:menuButton']") : document.querySelector("[g-ref='Nav:logo']");
  this.positionNotifications(notifications, { originEl, topDistanceToOrigin: is_mobile ? 8 : 0 });
  //                                                    ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  //                                                    ↑ Ensures proper distance btw. 1st notification
  //                                                      and mobile menu button

  // Listen to clicks on notification close-button...
  notifications.forEach((notifEl) => {
    const closeButton = notifEl.querySelector(".closeButton");
    if (!closeButton) return;
    closeButton.addEventListener("click", eh_closeButton_click.bind(this, notifEl));
  });

  // Set init. notification states...
  this.setState({
    notificationStates: notifications.map((notif) => {
      return { id: notif.dataset.id, visibility: "visible" };
    }),
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_notifications;
