// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import positionNotifications from "./utilities/positionNotifications";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_notifications from "./init/init_notifications.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_FrontPageScroller_scrollDirChange from "./eventbusHandlers/ebh_FrontPageScroller_scrollDirChange.js";

import stChH_notificationStates from "./stateChangeHandlers/stChH_notificationStates.js";
import stChH_notificationWidth from "./stateChangeHandlers/stChH_notificationWidth.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class FrontPageNotifications extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = { notifications: [] };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "FrontPageNotifications",
      run_withLogs: "defaultValue",
      logStyles: "defaultValue",
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.positionNotifications = positionNotifications.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_notifications = init_notifications.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);
    this.ebl_FrontPageScroller_scrollDirChange = ebh_FrontPageScroller_scrollDirChange.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_notificationStates = stChH_notificationStates.bind(this);
    this.stChL_notificationWidth = stChH_notificationWidth.bind(this);

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("FrontPageScroller_scroll", this.ebl_FrontPageScroller_scroll);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_states();
    this.init_notifications();
    this.init_eventbus();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({ is_mobile: window.innerWidth < 640 });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("FrontPageScroller_scrollDirChange", this.ebl_FrontPageScroller_scrollDirChange);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    //////// notificationStates ////////
    ////////////////////////////////////

    if ("notificationStates" in CHANGES) this.stChL_notificationStates(CHANGES);

    //////// notificationWidth ////////
    ////////////////////////////////////

    if ("notificationWidth" in CHANGES) this.stChL_notificationWidth(CHANGES);

    ////////////// Change //////////////
    ////////////////////////////////////

    if ("change" in CHANGES) this.stChL_change(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default FrontPageNotifications;
