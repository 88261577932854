// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_fragmPageCloseButton_click from "../eventHandlers/eh_fragmPageCloseButton_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  noValidDOMEl: ["init. frag. page close-button", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_fragmPageCloseButton() {
  // Setup...
  const { fragmPageCloseButton } = this.ref;
  this.logger("init", ["fragmPageCloseButton"], "action", { inline: true });

  // Guard...
  if (!fragmPageCloseButton || fragmPageCloseButton instanceof HTMLElement === false) {
    this.logger("error", loggerMsgs.noValidDOMEl, "error", { inline: true });
    return;
  }

  // Listen to clicks on the frag. page close-button...
  fragmPageCloseButton.addEventListener("click", eh_fragmPageCloseButton_click.bind(this));

  // Set initial active- & hidden-state...
  const { fragmPageCloseButtonActive } = this.options;
  this.setState({
    fragmPageCloseButtonActive,
    fragmPageCloseButtonHidden: true,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_fragmPageCloseButton;
