// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_backToTopButton_click from "../eventHandlers/eh_backToTopButton_click";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_backToTopButton() {
  // Setup...
  const { backToTopButton } = this.ref;
  this.logger("init", ["back-to-top button"], "action", { inline: true });

  // Guard...
  if (!backToTopButton || backToTopButton instanceof HTMLElement === false) {
    this.logger("error", ["back-to-top button", "no valid DOM element provided"], "error", { inline: true });
    return;
  }

  // Listen to clicks on the back-to-top button...
  backToTopButton.addEventListener("click", eh_backToTopButton_click.bind(this));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_backToTopButton;
