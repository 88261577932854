// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  switchingToMobileSrc: ["window width < mobile breakpoint", "switching to mobile src."],
  switchingToDesktopSrc: ["window width > mobile breakpoint", "switching to desktop src."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_window_resize(ARGS) {
  // Setup...
  const { src_full, src_full_mobile, has_fullVideo, has_fullVideo_mobile } = this.options;
  const { windowWidth, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Record in state if on mobile/desktop screen...
  this.setState({ is_mobile: windowWidth < 640 });

  // If window width below mobile breakpoint, switch to mobile src. if available
  if (windowWidth < 640) {
    this.logger("info", loggerMessages.switchingToMobileSrc, "info", { inline: true });
    if (has_fullVideo_mobile) this.setState({ sourceType: "full_mobile", source: src_full_mobile });
    else this.logger("warning", ["no mobile src. available"], "warning", { inline: true });
  } else {
    this.logger("info", loggerMessages.switchingToDesktopSrc, "info", { inline: true });
    if (has_fullVideo) this.setState({ sourceType: "full", source: src_full });
    else this.logger("warning", ["no desktop src. available"], "warning", { inline: true });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_window_resize;
