// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import get_elementSize_fromChildren from "../../../../../app/baseUtilities/get/get_elementSize_fromChildren.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_contentBgSize() {
  // Setup...
  const { is_mobile } = this.state;
  const { contentWrapper } = this.ref;
  const message = ["measuring content wrapper + setting content backgr. width & height"];
  this.logger("info", message, "action", { inline: true });

  // Guard...
  if (!contentWrapper || contentWrapper instanceof HTMLElement === false) {
    this.logger("error", ["set content backgr. width & height", "no valid DOM elements provided"], "error", { inline: true });
    return;
  }

  // Set content backgr. width/height...
  const contentWrapperSize = get_elementSize_fromChildren(contentWrapper);
  this.setState({
    contentBgSize: {
      w: is_mobile ? window.innerWidth : contentWrapperSize.w,
      h: contentWrapperSize.h,
    },
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
