// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_fileLoad(EVENTDATA) {
  // Setup...
  const { tolog_events } = this.options;
  const { rawResult, item } = EVENTDATA;
  const { id, src, type } = item;
  if (tolog_events.find((e) => e.name === "fileLoad").log === "true") {
    this.logger("event", ["file loaded", EVENTDATA], "event");
    this.logger("info", ["raw load result:", rawResult]);
    this.logger("info", ["loaded item:", item]);
  }

  // Send notification about loaded asset via eventbus...
  // (will be picked up by componets requiring preloaded assets)
  const args = {
    type,
    orgSrc: src,
    URLobject: URL.createObjectURL(rawResult),
  };
  eventbus.emit("Preloader_fileLoad", args);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_fileLoad;
