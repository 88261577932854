// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../../../app/baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_notificationWidth(CHANGES) {
  // Setup...
  const { notifications } = this.ref;
  const { notificationWidth } = CHANGES;
  this.logger("state-change", [`notificationWidth: ${notificationWidth}`], "default", { inline: true });

  // Apply width to all noitification elements...
  notifications.forEach((notif) => updt_styleProp(notif, "--w", notificationWidth));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_notificationWidth;
