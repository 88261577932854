// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ————————————————————————————————————————— PARENT CLASS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import BaseScrollArea from "../../component-base-scroll-area/js/BaseScrollArea";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_scroll from "./eventHandlers/eh_scroll";

import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js";
import ebh_backToTopButton_click from "./eventbusHandlers/ebh_backToTopButton_click.js";
import ebh_ScrollMenu_linkClick from "./eventbusHandlers/ebh_ScrollMenu_linkClick.js";
import ebh_Nav_stCh_showSimpleLangContent from "./eventbusHandlers/ebh_Nav_stCh_showSimpleLangContent.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class ScrollArea extends BaseScrollArea {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      ...super.ref,
    };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "ScrollArea",
      is_onParentPage: "defaultValue",
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    // ...

    //////////// Init. f() /////////////
    ////////////////////////////////////

    // ...

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);
    this.ebl_backToTopButton_click = ebh_backToTopButton_click.bind(this);
    this.ebl_ScrollMenu_linkClick = ebh_ScrollMenu_linkClick.bind(this);
    this.ebl_Nav_stCh_showSimpleLangContent = ebh_Nav_stCh_showSimpleLangContent.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    // ...

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    this.customEventHandlers = {
      scroll: eh_scroll.bind(this),
    };

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    super.mount();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    super.unmount();

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("swup_page_load", this.ebl_swup_page_load);
    eventbus.off("backToTopButton_click", this.ebl_backToTopButton_click);
    eventbus.off("ScrollMenu_linkClick", this.ebl_ScrollMenu_linkClick);
    eventbus.off("Nav_stCh_showSimpleLangContent", this.ebl_Nav_stCh_showSimpleLangContent);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    super.init();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    super.init_states();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    super.init_eventbus();

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("swup_page_load", this.ebl_swup_page_load);
    eventbus.on("backToTopButton_click", this.ebl_backToTopButton_click);
    eventbus.on("ScrollMenu_linkClick", this.ebl_ScrollMenu_linkClick);
    eventbus.on("Nav_stCh_showSimpleLangContent", this.ebl_Nav_stCh_showSimpleLangContent);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    super.stateChange(CHANGES);

    ////////////// Change //////////////
    ////////////////////////////////////

    if ("change" in CHANGES) this.stChL_change(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ScrollArea;
