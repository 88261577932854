// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../baseUtilities/updt/updt_styleProp";
import make_randomInt from "../../../baseUtilities/make/make_randomInt";

function make_skewDegree(RANGE) {
  const min = RANGE[0];
  const max = RANGE[1];
  return make_randomInt({ min, max });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function skewLetters(TEXTELEMENT) {
  if (!TEXTELEMENT || TEXTELEMENT instanceof HTMLElement === false) {
    this.logger("error", ["skewLetters", "no valid DOM element provided"], "error", { inline: true });
    return;
  }

  // Setup...
  const skewRange = JSON.parse(TEXTELEMENT.dataset.rztTextToSkewRange);

  // Skew letter elements inside text element by random degree...
  const letters = [...TEXTELEMENT.querySelectorAll("span:not([data-rzt-text-to-skew-word-wrapper])")];
  for (let l of letters) updt_styleProp(l, "--skew", `${make_skewDegree(skewRange)}deg`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default skewLetters;
