// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_player from "./init/init_player";
import init_source from "./init/init_source";
import init_playButton from "./init/init_playButton";
import init_soundButton from "./init/init_soundButton";
import init_fullscreenButton from "./init/init_fullscreenButton";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize";

import stChH_playback from "./stateChangeHandlers/stChH_playback";
import stChH_source from "./stateChangeHandlers/stChH_source";
import stChH_muted from "./stateChangeHandlers/stChH_muted";
import stChH_time from "./stateChangeHandlers/stChH_time";
import stChH_mode from "./stateChangeHandlers/stChH_mode";
import stChH_fullscreen from "./stateChangeHandlers/stChH_fullscreen";
import stChH_loadingCover from "./stateChangeHandlers/stChH_loadingCover.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class BaseVideoPlayer extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      media: null,
      playButton: null,
      playButton_mobile: null,
      soundButton: null,
      soundButton_mobile: null,
      fullscreenButton: null,
      fullscreenButton_mobile: null,
      closeButton: null,
      loadingCover: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "Base Video Player",
      run_withLogs: "defaultValue",
      logStyles: "defaultValue",
      //////
      autoplay: "defaultValue",
      muted: "defaultValue",
      loop: "defaultValue",
      //////
      loadingCoverFadeOutDelay: "defaultValue",
      //////
      src_poster: "defaultValue",
      src_teaser: "defaultValue",
      src_full: "defaultValue",
      src_teaser_mobile: "defaultValue",
      src_full_mobile: "defaultValue",
      //////
      has_teaser: "defaultValue",
      has_teaser_mobile: "defaultValue",
      has_fullVideo: "defaultValue",
      has_fullVideo_mobile: "defaultValue",
      //////
      has_loadingCover: true,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_source = init_source.bind(this);
    this.init_player = init_player.bind(this);
    this.init_playButton = init_playButton.bind(this);
    this.init_soundButton = init_soundButton.bind(this);
    this.init_fullscreenButton = init_fullscreenButton.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = { videojs: {} };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_loadingCover = stChH_loadingCover.bind(this);
    this.stChL_playback = stChH_playback.bind(this);
    this.stChL_source = stChH_source.bind(this);
    this.stChL_muted = stChH_muted.bind(this);
    this.stChL_fullscreen = stChH_fullscreen.bind(this);
    this.stChL_time = stChH_time.bind(this);
    this.stChL_mode = stChH_mode.bind(this);
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Note: global comp. are never unmounted

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    // Remove player instance from DOM & memory...
    this.modules.videojs.instance.dispose();

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  async init(
    CONFIG = {
      init_source: true,
      init_playButton: true,
      init_mobilePlayButton: false,
      init_soundButton: true,
      init_mobileSoundButton: false,
      init_fullscreenButton: false,
      init_fullscreenButton_mobile: false,
      eventHandlers: [],
    }
  ) {
    const { playButton_mobile, soundButton_mobile, fullscreenButton_mobile } = this.ref;
    const {
      init_source,
      init_playButton,
      init_mobilePlayButton,
      init_soundButton,
      init_mobileSoundButton,
      init_fullscreenButton,
      init_fullscreenButton_mobile,
      eventHandlers,
    } = CONFIG;
    //////
    this.modules.videojs.instance = await this.init_player(eventHandlers);
    this.logger("info", ["videoJs instance ready, init. rest of comp."], "action");
    this.init_states();
    this.init_eventbus();
    //////
    if (init_playButton) this.init_playButton();
    if (init_mobilePlayButton) this.init_playButton(playButton_mobile);
    if (init_soundButton) this.init_soundButton();
    if (init_mobileSoundButton) this.init_soundButton(soundButton_mobile);
    if (init_fullscreenButton) this.init_fullscreenButton();
    if (init_fullscreenButton_mobile) this.init_fullscreenButton(fullscreenButton_mobile);
    if (init_source) this.init_source();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    const { muted, autoplay, autoplayAfterSrcLoad } = this.options;
    this.setState({
      sourceInitialised: false,
      playback: "pause",
      muted: muted,
      autoplayAfterSrcLoad: autoplay || autoplayAfterSrcLoad,
      sendTimeUpdates: false,
      mode: "default",
      //////
      is_mobile: window.innerWidth <= 640,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });
    eventbus.on("window_resize", this.ebl_window_resize);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    const { has_loadingCover } = this.options;

    /////////// loadingCover ///////////
    ////////////////////////////////////

    if ("loadingCover" in CHANGES && has_loadingCover) this.stChL_loadingCover(CHANGES);

    ////////////// Source //////////////
    ////////////////////////////////////

    if ("source" in CHANGES) this.stChL_source(CHANGES);

    ///////////// Playback /////////////
    ////////////////////////////////////

    if ("playback" in CHANGES) this.stChL_playback(CHANGES);

    //////////// Mute/unmute ///////////
    ////////////////////////////////////

    if ("muted" in CHANGES) this.stChL_muted(CHANGES);

    /////////////// Time ///////////////
    ////////////////////////////////////

    if ("time" in CHANGES) this.stChL_time(CHANGES);

    /////////////// Mode ///////////////
    ////////////////////////////////////

    if ("mode" in CHANGES) this.stChL_mode(CHANGES);

    //////////// fullscreen ////////////
    ////////////////////////////////////

    if ("fullscreen" in CHANGES) this.stChL_fullscreen(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default BaseVideoPlayer;
