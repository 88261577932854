// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  srcCanBePlayed: (playAfterSrcLoad) => ["player source can be played", `${!playAfterSrcLoad ? "NOT" : ""} triggering playback`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_canPlay() {
  // Setup...
  const state = this.state;
  const { autoplayAfterSrcLoad: playAfterSrcLoad } = state;
  this.logger("event", ["can-play"], "event", { inline: true });

  // Start playing when source buffered enough (prevent playback before source available)...
  this.logger("event", loggerMessages.srcCanBePlayed(playAfterSrcLoad), "event", { inline: true });
  if (playAfterSrcLoad) this.setState({ playback: "play" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_canPlay;
