// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_menuButton_click from "../eventHandlers/eh_menuButton_click";
import eh_menuCloseButton_click from "../eventHandlers/eh_menuCloseButton_click";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_menuButton() {
  // Setup...
  const { menuButton, menuCloseButton } = this.ref;
  this.logger("init", ["menu-button & menu-close-button"], "action", { inline: true });

  // Guard...
  if (![menuButton, menuCloseButton].every((el) => el && el instanceof HTMLElement === true)) {
    this.logger("error", ["init. menu-button & menu-close-button", "no valid DOM elements provided"], "error", { inline: true });
    return;
  }

  ////////////////////
  // Event handling //
  ////////////////////

  this.logger("init", ["menu-button & menu-close-button", "event handling"], "action", { inline: true });
  menuButton.addEventListener("click", eh_menuButton_click.bind(this));
  menuCloseButton.addEventListener("click", eh_menuCloseButton_click.bind(this));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_menuButton;
