// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import toggle_class from "../../../../../app/baseUtilities/toggle/toggle_class";
import updt_styleProp from "../../../../../app/baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  stateChange: (CHANGE) => [`background-image URL: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_bgImgUrl(CHANGES) {
  // Setup...
  const { backgroundLayers } = this.ref;
  const { bgImgUrl } = CHANGES;
  this.logger("state-change", loggerMessages.stateChange(bgImgUrl), "default", { inline: true });

  ////////////////////////////
  // Background image updt. //
  ////////////////////////////

  // Find active & inactive background layer...
  const layer_inactive = backgroundLayers.find((layer) => layer.getAttribute("data-is-active") === "false");
  const layer_active = backgroundLayers.find((layer) => layer.getAttribute("data-is-active") === "true");

  // Updt. backgr. image URL of unused layer & move to top...
  toggle_class(layer_inactive, "z-[1]", null, true);
  layer_inactive.setAttribute("data-is-active", "true");
  updt_styleProp(layer_inactive, "--imgURL", `url(${bgImgUrl})`);

  // Deactivate last used layer & move to bottom...
  toggle_class(layer_active, "z-[1]", null, false);
  layer_active.setAttribute("data-is-active", "false");

  // Hide both layers, if no backgr. img. URL available...
  for (let layer of [layer_active, layer_inactive]) toggle_class(layer, "opacity-0", null, bgImgUrl === "null");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
