// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../../../app/baseUtilities/validate/validate_refEl.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. map"],
  initSuccess: ["init. map", "init. success"],
  noValidDOMEl: ["init. map", "no valid DOM element provided"],
};

const mapOptionsTemplate = (LAT, LONG) => ({
  center: { lat: LAT, lng: LONG },
  zoom: 18,
  disableDefaultUI: true,
  zoomControl: true,
  scrollwheel: false,
});

const mapStyles = {
  hide: [
    { featureType: "poi.business.shopping", stylers: [{ visibility: "off" }] },
    { featureType: "poi.business.lodging", stylers: [{ visibility: "off" }] },
    { featureType: "poi.business.food_and_drink", stylers: [{ visibility: "off" }] },
    { featureType: "transit", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
  ],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_map(GOOGLECLIENT) {
  if (!GOOGLECLIENT) return;

  // Setup...
  const { latitude, longitude } = this.options;
  const { canvas } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!validate_refEL(canvas)) this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Create map instance...
  const mapOptions = mapOptionsTemplate(latitude, longitude);
  const mapInstance = new GOOGLECLIENT.maps.Map(canvas, mapOptions);
  mapInstance.setOptions({ styles: mapStyles.hide });

  // Create marker...
  const marker = new GOOGLECLIENT.maps.Marker({ position: mapOptions.center, map: mapInstance });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
