// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_notifBadgeAnim(CHANGES) {
  // Setup...
  const { notificationBadge } = this.ref;
  const { notifBadgeAnim: notifBadgeAnimState } = CHANGES;
  this.logger("state-change", [`notif. badge anim.:`, notifBadgeAnimState], "default", { inline: true });

  // Anim. direction...
  if ("direction" in notifBadgeAnimState) {
    const direction = notifBadgeAnimState.direction;
    notificationBadge.setAttribute("data-direction", direction);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
