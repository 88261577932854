// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_activeMenuLink(CHANGES) {
  // Setup...
  const { activeMenuLink } = CHANGES;
  const { menuLinks, langLinks } = this.ref;
  this.logger("state-change", [`activeMenuLink: ${activeMenuLink}`], "default", { inline: true });

  // Toggle active state on menu link elements...
  menuLinks.forEach((linkEl) => {
    const linkID = linkEl.dataset.id;
    linkEl.setAttribute("data-is-active", linkID === activeMenuLink ? "true" : "false");
  });

  // Updt. target URL of lang. links...
  langLinks.forEach((linkEl) => {
    const { langId } = linkEl.dataset;
    const langLinkURL = activeMenuLink === "home" ? "/" : `/${langId}/${activeMenuLink}`;
    linkEl.setAttribute("href", langLinkURL);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_activeMenuLink;
