// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../../../app/baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_contentBgSize(CHANGES) {
  // Setup...
  const { contentBg } = this.ref;
  const { contentBgSize } = CHANGES;
  const { w: contentBgWidth, h: contentBgHeight } = contentBgSize;
  this.logger("state-change", [`content backgr. size`, contentBgSize]);

  // Update content wrapper element width/height...
  updt_styleProp(contentBg, "--w", `${contentBgWidth}px`);
  updt_styleProp(contentBg, "--h", `${contentBgHeight}px`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_contentBgSize;
