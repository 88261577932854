// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_openButton_click from "../eventHandlers/eh_openButton_click.js";
import eh_closeButton_click from "../eventHandlers/eh_closeButton_click.js";
import eh_openButton_mouseEnter from "../eventHandlers/eh_openButton_mouseEnter.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_buttons() {
  // Setup...
  const { openButton, closeButton } = this.ref;
  this.logger("init", ["buttons"], "action", { inline: true });

  // Guard...
  if (
    (!openButton || openButton instanceof HTMLElement === false) &&
    (!closeButton || closeButton instanceof HTMLElement === false)
  ) {
    this.logger("error", ["init. buttons", "no valid DOM elements provided"], "error", { inline: true });
    return;
  }

  // Init. open-button...
  if (openButton && openButton instanceof HTMLElement === true) {
    this.logger("init", ["open-button"], "action", { inline: true });

    // Listen to mouse events on open-button...
    openButton.addEventListener("click", eh_openButton_click.bind(this));
    openButton.addEventListener("mouseenter", eh_openButton_mouseEnter.bind(this));
  }

  // Init. close-button...
  if (closeButton && closeButton instanceof HTMLElement === true) {
    this.logger("init", ["close-button"], "action", { inline: true });

    // Listen to mouse events on close-button...
    closeButton.addEventListener("click", eh_closeButton_click.bind(this));
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_buttons;
