// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../../../app/baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_progress(CHANGES) {
  // Setup...
  const refs = this.ref;
  const opts = this.options;
  const { progressBar } = refs;
  const { tolog_statechanges } = opts;
  const { progress } = CHANGES;
  if (tolog_statechanges.find((e) => e.name === "progress")?.log === "true")
    this.logger("state-change", [`progress: ${progress}% `], "default", { inline: true });

  // Updt. progress var. on progress bar element...
  updt_styleProp(progressBar, "--progress", `${progress}%`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_progress;
