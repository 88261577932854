// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_playButton_mouseLeave() {
  // Setup...
  const { eventbusCallName } = this.options;
  this.logger("event", ["mouse left play-button"], "event", { inline: true });

  // Notify other components...
  // ...Cursor (show)
  eventbus.emit(`${eventbusCallName}_controls_mouseLeave`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_playButton_mouseLeave;
