// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ————————————————————————————————————————— PARENT CLASS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import BaseVideoPlayer from "../../component-base-video-player/js/BaseVideoPlayer";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_Preloader_fileLoad from "./eventbusHandlers/ebh_Preloader_fileLoad.js";
import ebh_FrontPageScroller_card_mouseEnter from "./eventbusHandlers/ebh_FrontPageScroller_card_mouseEnter.js";
import ebh_FrontPageScroller_stCh_cardStates from "./eventbusHandlers/ebh_FrontPageScroller_stCh_cardStates.js";
import ebh_swup_link_click from "./eventbusHandlers/ebh_swup_link_click.js";
import ebh_swup_history_popstate from "./eventbusHandlers/ebh_swup_history_popstate.js";
import ebh_Nav_stCh_mediaToggleMode from "./eventbusHandlers/ebh_Nav_stCh_mediaToggleMode.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class VideoPlayer extends BaseVideoPlayer {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      ...super.ref,
    };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      id: "defaultValue",
      name: "VideoPlayer",
      run_withLogs: false,
      logStyles: {
        default: "#4361BC",
        action: "#c7d0ff",
        event: "#97a5ce",
        warning: "#ffaf00",
        error: "#ff3232",
      },
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    // ...

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_Preloader_fileLoad = ebh_Preloader_fileLoad.bind(this);
    this.ebl_FrontPageScroller_card_mouseEnter = ebh_FrontPageScroller_card_mouseEnter.bind(this);
    this.ebl_FrontPageScroller_stCh_cardStates = ebh_FrontPageScroller_stCh_cardStates.bind(this);
    this.ebl_swup_link_click = ebh_swup_link_click.bind(this);
    this.ebl_swup_history_popstate = ebh_swup_history_popstate.bind(this);
    this.ebl_Nav_stCh_mediaToggleMode = ebh_Nav_stCh_mediaToggleMode.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    super.mount();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    super.unmount();

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("Preloader_fileLoad", this.ebl_Preloader_fileLoad);
    eventbus.off("FrontPageScroller_card_mouseEnter", this.ebl_FrontPageScroller_card_mouseEnter);
    eventbus.off("FrontPageScroller_stCh_cardStates", this.ebl_FrontPageScroller_stCh_cardStates);
    eventbus.off("swup_link_click", this.ebl_swup_link_click);
    eventbus.off("swup_history_popstate", this.ebl_swup_history_popstate);
    eventbus.off("Nav_stCh_mediaToggleMode", this.ebl_Nav_stCh_mediaToggleMode);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  async init() {
    const { srcWillBePreloaded } = this.options;
    await super.init({
      init_source: !srcWillBePreloaded,
      init_playButton: false,
      init_soundButton: false,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    super.init_states();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    super.init_eventbus();
    eventbus.on("Preloader_fileLoad", this.ebl_Preloader_fileLoad);
    eventbus.on("FrontPageScroller_card_mouseEnter", this.ebl_FrontPageScroller_card_mouseEnter);
    eventbus.on("FrontPageScroller_stCh_cardStates", this.ebl_FrontPageScroller_stCh_cardStates);
    eventbus.on("swup_link_click", this.ebl_swup_link_click);
    eventbus.on("swup_history_popstate", this.ebl_swup_history_popstate);
    eventbus.on("Nav_stCh_mediaToggleMode", this.ebl_Nav_stCh_mediaToggleMode);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    super.stateChange(CHANGES);

    ////////////// Change //////////////
    ////////////////////////////////////

    if ("change" in CHANGES) this.stChL_change(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default VideoPlayer;
