// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function saveCookie(OPTIONS = { daysValid: 30 }) {
  // Setup...
  const { daysValid } = OPTIONS;
  this.logger("info", ["saving cookie"], "action");

  // Determ. expiration date...
  const now = new Date();
  const time = now.getTime() + daysValid * 24 * 60 * 60 * 1000;
  let newTime = new Date(now.setTime(time));
  newTime = newTime.toUTCString();

  // Set cookie...
  document.cookie = "cookieInfoHidden=1; expires=" + newTime + "; path=/";
}
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default saveCookie;
