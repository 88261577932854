// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_FrontPageScroller_card_mouseLeave(ARGS) {
  // Stop, if module instance is turned off...
  if (this.state.mode == "off") return;

  // Setup...
  const { runningSkewAnims } = this.state;
  const { cardId, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Stop animation for card...
  const skewAnim_card = runningSkewAnims.find((skewAnim) => skewAnim.id == cardId);
  if (!skewAnim_card) {
    this.logger("info", ["stopping skew anim. for card", "no skewAnim found for card"], "warning", { inline: true });
    return;
  }
  clearInterval(skewAnim_card.interval);

  // Remove stopped animation from state...
  this.setState({ runningSkewAnims: runningSkewAnims.filter((skewAnim) => skewAnim.id != cardId) });

  // Unskew letters...
  this.unskewLetters(skewAnim_card.element);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_FrontPageScroller_card_mouseLeave;
