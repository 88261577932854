// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../../../app/baseUtilities/validate/validate_refEl.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_openButton_click from "../eventHandlers/eh_openButton_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  noElements: ["init. buttons", "no valid DOM elements provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_buttons() {
  // Setup...
  const { openButton } = this.ref;
  this.logger("init", ["buttons"], "action", { inline: true });

  // Guard...
  if (!validate_refEL(openButton)) {
    this.logger("error", loggerMessages.noElements, "error", { inline: true });
    return;
  }

  // Init. open-button...
  this.logger("init", ["open-button"], "action", { inline: true });

  // Listen to mouse events on open-button...
  openButton.addEventListener("click", eh_openButton_click.bind(this));
  // openButton.addEventListener("mouseenter", eh_openButton_mouseEnter.bind(this));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
