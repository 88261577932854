// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function get_elementSize_fromChildren(ELEMENT) {
  if (!ELEMENT || ELEMENT instanceof Element === false) return false;

  // Setup...
  const elementPadding = {
    t: parseInt(window.getComputedStyle(ELEMENT).paddingTop),
    r: parseInt(window.getComputedStyle(ELEMENT).paddingRight),
    b: parseInt(window.getComputedStyle(ELEMENT).paddingBottom),
    l: parseInt(window.getComputedStyle(ELEMENT).paddingLeft),
  };

  // Determ. element width by measuring widest child element + possible element padding (r/l)...
  const childElements = ELEMENT.children;
  if (!childElements || childElements.length === 0) return false;
  let widestChildWidth = 0;
  [...childElements].forEach((el) => {
    const { width } = el.getBoundingClientRect();
    if (width > widestChildWidth) widestChildWidth = width;
  });
  const width = widestChildWidth + elementPadding.r + elementPadding.l;

  // Determ. element height by summing up height of all child elements + possible element padding (t/b)...
  let height = 0;
  [...childElements].forEach((el) => {
    const { height: childHeight } = el.getBoundingClientRect();
    height += childHeight;

    // Add possible el. margin (t/b)...
    const childMargin = {
      t: parseInt(window.getComputedStyle(el).marginTop),
      b: parseInt(window.getComputedStyle(el).marginBottom),
    };

    height += childMargin.t + childMargin.b;
  });

  height += elementPadding.t + elementPadding.b;

  return { w: width, h: height };
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— /
