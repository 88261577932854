// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_swup_history_popstate(ARGS) {
  // Setup...
  const { fragmIsBeingOpened, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Pause, when fragm. page is opened...
  if (fragmIsBeingOpened) this.setState({ playback: "pause" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_swup_history_popstate;
