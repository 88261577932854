// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_swup_page_view(ARGS) {
  // Setup...
  const { page_has_simpleLangVersion, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Respond to eventbus call...
  // - Toggle visibility of simple lang. switch
  // - Toggle active state of simple lang. switch
  // - Hide back-to-top button
  this.setState({
    simpleLangSwitchHidden: !page_has_simpleLangVersion,
    simpleLangSwitchActive: page_has_simpleLangVersion,
    backToTopButtonHidden: true,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_swup_page_view;
