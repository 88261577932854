// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_playButton_mouseEnter() {
  // Setup...
  const { eventbusCallName } = this.options;
  this.logger("event", ["mouse entered play-button"], "event", { inline: true });

  // Notify other components...
  // ...Cursor (hide)
  eventbus.emit(`${eventbusCallName}_controls_mouseEnter`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_playButton_mouseEnter;
