// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_contentWrapper_mouseLeaave() {
  // Setup...
  const { is_mobile } = this.state;
  this.logger("event", ["[message]"], "event", { inline: true });

  // Toggle visibility of content...
  if (is_mobile) return;
  this.setState({ contentVisibility: "hidden" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_contentWrapper_mouseLeaave;
