// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function lazyLoadImg(IMGWRAPPEREL) {
  if (!IMGWRAPPEREL) return console.warn("lazyLoadImg(): No img wrapper el. provided.");
  const imgEl = IMGWRAPPEREL.querySelector("img");
  if (!imgEl) return console.warn("lazyLoadImg(): No img el. found in member image wrapper.");
  imgEl.src = imgEl.dataset.src;
  imgEl.onload = () => setTimeout(() => IMGWRAPPEREL.setAttribute("data-img-is-loaded", true), 200);
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`active item ID: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeItemID(CHANGES) {
  if (!("activeItemID" in CHANGES)) return;

  // Setup...
  const { activeItemID } = CHANGES;
  const { listItems, memberImages } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeItemID), "default", { inline: true });

  // Updt. list item el. data-is-active attr...
  listItems.forEach((item) => item.setAttribute("data-is-active", item.dataset.id === activeItemID));

  // Updt. member image el. data-is-active attr...
  memberImages.forEach((imgElWrapper) => {
    // Determ. if + set active...
    const is_active = imgElWrapper.dataset.id === activeItemID;
    imgElWrapper.setAttribute("data-is-active", imgElWrapper.dataset.id === activeItemID);

    // Lazy load if necessary...
    const imgIsLoaded = imgElWrapper.dataset.imgIsLoaded === "true";
    if (!imgIsLoaded && is_active) lazyLoadImg(imgElWrapper);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
