// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../../../app/baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_menuSize(CHANGES) {
  // Setup...
  const { menu } = this.ref;
  const { menuSize } = CHANGES;
  const { w: menuWidth, h: menuHeight } = menuSize;
  this.logger("state-change", [`menu size:`, menuSize]);

  // Update menu element width...
  updt_styleProp(menu, "--w", `${menuWidth}px`);
  updt_styleProp(menu, "--h", `${menuHeight}px`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_menuSize;
