// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../baseUtilities/logger";
import set_state from "../../baseUtilities/state/set_state";
import skewElement from "./utilities/skewElement";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_links from "./init/init_links";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_content_replace from "./eventbusHandlers/ebh_swup_content_replace";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class LinksAndButtons {
  //////////////////////////////// Constructor /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      logs: {
        run_withLogs: false,
        logStyles: {
          default: "#4361BC",
          action: "#c7d0ff",
          event: "#97a5ce",
          warning: "#ffaf00",
          error: "#ff3232",
        },
      },
      linkElementSkewing: {
        maxDegree: 30,
        minDegree: -30,
      },
    },
    OPTIONS
  ) {
    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      name: "linksAndButtons",
      run_withLogs: CONFIG?.logs?.run_withLogs,
      logStyles: CONFIG?.logs?.logStyles,
      //////
      linkElementSkewing: CONFIG?.linkElementSkewing,
    };

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);
    this.skewElement = skewElement.bind(this);

    /////////////////////// Init. f() //////////////////////////
    ////////////////////////////////////////////////////////////

    this.init_links = init_links.bind(this);

    //////////////////// Eventbus listeners ////////////////////
    ////////////////////////////////////////////////////////////

    this.ebl_swup_content_replace = ebh_swup_content_replace.bind(this);
  }

  ////////////////////////////// State management //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState instead."], "warning");
    this._state = state;
  }

  stateChange(CHANGES) {
    console.log("stateChange", CHANGES);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.logger("init", ["module"], "default", { inline: true });
    this.init_eventbus();
    this.init_links();
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    // Listener registration //
    eventbus.on("swup_content_replace", this.ebl_swup_content_replace);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default LinksAndButtons;
