// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_swup_page_load(ARGS) {
  // Setup...
  const { fragmIsBeingOpened, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Set component mode to 'active' if nav. to fragm. page...
  this.setState({ mode: fragmIsBeingOpened ? "active" : "inactive" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_swup_page_load;
