// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import make_randomFloat from "../../../../../app/baseUtilities/make/make_randomFloat";

function set_SVGLineParams(SVGLine, CONFIG = { paramToSet: "x1", min: 0, max: 10 }) {
  if (!SVGLine || !CONFIG) return;
  const { paramToSet, min, max } = CONFIG;
  const randParamValue = make_randomFloat({ min, max });
  SVGLine.setAttribute(paramToSet, randParamValue);
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mediaToggleState(CHANGES) {
  // Setup...
  const { mediaToggle, mediaToggleCrossOutSVG } = this.ref;
  const { mediaToggleState } = CHANGES;
  this.logger("state-change", [`media-toggle state`, mediaToggleState]);

  // Disable/Enable media toggle...
  if ("is_active" in mediaToggleState) {
    const { is_active } = mediaToggleState;
    mediaToggle.setAttribute("data-is-active", is_active);
  }

  // Hdl. mode change...
  if ("mode" in mediaToggleState) {
    // Updt. mode attr. on media toggle el...
    const { mode } = mediaToggleState;
    mediaToggle.setAttribute("data-mode", mode);

    // Set cross-out SVG lines (randomize params.)...
    if (mode === "off") {
      const lineA = mediaToggleCrossOutSVG.querySelector(".line-a");
      const lineB = mediaToggleCrossOutSVG.querySelector(".line-b");

      // Set line A params...
      set_SVGLineParams(lineA, { paramToSet: "y1", min: 35, max: 43 });
      set_SVGLineParams(lineA, { paramToSet: "x1", min: -5, max: 10 });
      set_SVGLineParams(lineA, { paramToSet: "y2", min: -4, max: 5 });
      set_SVGLineParams(lineA, { paramToSet: "x2", min: 60, max: 70 });

      // Set line B params...
      set_SVGLineParams(lineB, { paramToSet: "y1", min: 26, max: 40 });
      set_SVGLineParams(lineB, { paramToSet: "x1", min: 56, max: 72 });
      set_SVGLineParams(lineB, { paramToSet: "y2", min: 0, max: 10 });
      set_SVGLineParams(lineB, { paramToSet: "x2", min: -6, max: 1 });
    }

    // Notify other components...
    // ↳ App                → toggle global show-media attr. + set global mediaIsEnabled state
    // ↳ Frontpage Scroller → hide card media
    eventbus.emit("Nav_stCh_mediaToggleMode", { mediaToggleMode: mode });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
