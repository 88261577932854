// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_contentVisibility(CHANGES) {
  // Setup...
  const { notifBadgeAnim } = this.state;
  const { openButton, contentWrapper, contentBg } = this.ref;
  const { contentVisibility } = CHANGES;
  this.logger("state-change", [`contentVisibility: ${contentVisibility}`], "default", { inline: true });

  // Hide/show content...
  openButton.setAttribute("data-is-hidden", contentVisibility === "visible");
  contentWrapper.setAttribute("data-is-hidden", contentVisibility === "hidden");
  contentBg.setAttribute("data-is-hidden", contentVisibility === "hidden");

  // Update content wrapper element width/height...
  if (contentVisibility === "visible") this.set_contentBgSize();

  // Switch notif. bubble anim. direction...
  if (contentVisibility === "visible")
    setTimeout(() => {
      this.setState({
        notifBadgeAnim: {
          direction: notifBadgeAnim.direction === "left" ? "right" : "left",
        },
      });
    }, 200);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_contentVisibility;
