// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_selectedSlideIndex(CHANGES) {
  // Setup...
  const opts = this.options;
  const { slideCounter } = this.ref;
  const { selectedSlideIndex } = CHANGES;
  this.logger("state-change", [`selected slide index: ${selectedSlideIndex}`], "default", { inline: true });

  // Updt. slide counter text...
  slideCounter.querySelector("div span").textContent = selectedSlideIndex;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_selectedSlideIndex;
