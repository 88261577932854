// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_swup_page_load(ARGS) {
  // Setup...
  const { mediaToggleState } = this.state;
  const { to: swupTrans_to, fragmIsBeingOpened, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  // Determ. incoming route to set active menu link...
  const target_isRoot = swupTrans_to.url === "/";
  let currentRouteID = target_isRoot ? "home" : swupTrans_to.url.slice(1);
  if (currentRouteID.startsWith("de/") || currentRouteID.startsWith("en/")) currentRouteID = currentRouteID.slice(3); // ← remove lang. prefix

  // Set states...
  // ↳ Set active menu link
  // ↳ Toggle active state of fragm. page close-button
  // ↳ Hide fragm. page close-button
  // ↳ Reveal (reactivate) media toggle (if hidden)
  this.setState({
    activeMenuLink: currentRouteID,
    fragmPageCloseButtonActive: fragmIsBeingOpened,
    fragmPageCloseButtonHidden: true,
  });
  if (!mediaToggleState.is_active) this.setState({ mediaToggleState: { is_active: true } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
