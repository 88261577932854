// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import check_el_isInView from "../../../../../app/baseUtilities/check/check_el_isInView";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Variables
let footer_is_inView_prev = false;

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_scroll(EVENTDATA) {
  // Setup...
  const { direction: direction_prev } = this.state;
  const { name, with_footer } = this.options;
  const { direction } = EVENTDATA;
  // this.logger("event", ["lenis instance scroll"], "event", { inline: true });

  ///////////////////////////
  // Scroll event handling //
  ///////////////////////////

  // Notify other components of scroll event...
  eventbus.emit(`${name}_scroll`, { direction });

  ////////////////////////////////////////////
  // Footer in-view check & change handling //
  ////////////////////////////////////////////

  let footer_is_inView = false;
  if (with_footer) {
    const pageFooterEl = document.getElementById("pageFooter");
    if (!pageFooterEl) return;
    const inViewThreshold = { top: 50, bottom: 50 };
    footer_is_inView = check_el_isInView(pageFooterEl, { threshold: inViewThreshold });

    // When footer comes in/out of view, notify other components...
    // ↳ Nav → Toggle visibility of back-to-top button & media toggle
    if (footer_is_inView !== footer_is_inView_prev) {
      eventbus.emit("ScrollArea_footerInViewChange", { footer_is_inView });
      footer_is_inView_prev = footer_is_inView;
    }
  }

  ///////////////////////////////
  // Direction change handling //
  ///////////////////////////////

  // Check if scroll direction has changed & update direction state...
  const directionHasChanged = direction !== direction_prev;
  if (directionHasChanged) this.setState({ direction });

  // Notify other components of scroll direction change...
  // ↳ Nav → Toggle visibility of back-to-top button & simple lang. switch
  if (directionHasChanged) {
    const args = { direction: direction === 1 ? "down" : "up", footer_is_inView };
    eventbus.emit(`${name}_scrollDirChange`, args);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
