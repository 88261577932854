// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_footerInView(CHANGES) {
  // Setup...
  const { is_mobile } = this.state;
  const { openButton, scrollProgressIndicator } = this.ref;
  const { footerInView } = CHANGES;
  this.logger("state-change", [`footer is in view: ${footerInView}`], "default", { inline: true });

  // Show/hide open-button & scroll progress indic. element (mobile only)...
  if (!is_mobile) return;
  openButton.setAttribute("data-is-hidden-off-screen", footerInView);
  scrollProgressIndicator.setAttribute("data-is-hidden", footerInView);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
