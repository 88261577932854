// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_flickity from "./init/init_flickity.js";
import init_buttons from "./init/init_buttons.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_selectedSlideIndex from "./stateChangeHandlers/stChH_selectedSlideIndex.js";
import stChH_caption from "./stateChangeHandlers/stChH_caption";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class Slider extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      slidesWrapper: null,
      slideCounter: null,
      buttons: [],
      captionDisplay: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "Slider",
      run_withLogs: "defaultValue",
      logStyles: "defaultValue",
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.cancel_featureInit = cancel_featureInit.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_buttons = init_buttons.bind(this);
    this.init_flickity = init_flickity.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      flickity: { instance: this.init_flickity() },
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_selectedSlideIndex = stChH_selectedSlideIndex.bind(this);
    this.stChL_caption = stChH_caption.bind(this);

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_states();
    this.init_eventbus();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });

    // ...
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    //////// selectedSlideIndex ////////
    ////////////////////////////////////

    if ("selectedSlideIndex" in CHANGES) this.stChL_selectedSlideIndex(CHANGES);

    ///////////// caption //////////////
    ////////////////////////////////////

    if ("caption" in CHANGES) this.stChL_caption(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default Slider;
