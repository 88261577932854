// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_fullscreen(CHANGES) {
  // Setup...
  const { fullscreen } = CHANGES;
  this.logger("state-change", [`fullscreen: ${fullscreen}`], "default", { inline: true });

  // Take video-js instance into/out of fullscreen...
  const videojsInstance = this.modules.videojs.instance;
  if (!videojsInstance) return;
  if (fullscreen) videojsInstance.requestFullscreen();
  else videojsInstance.exitFullscreen();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_fullscreen;
