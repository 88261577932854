// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_cardStates(CHANGES) {
  // Setup...
  const { cards } = this.ref;
  const { cardStates } = CHANGES;
  this.logger("state-change", [`cardStates:`, cardStates]);

  // Updt. cards’ in-view attr...
  cards.forEach((card, i) => {
    const { is_inView } = cardStates[i];
    card.setAttribute("data-is-in-view", is_inView);
  });

  // Notify other components...
  // ↳ LetterSkewer → Toggle letter skewing anim. of cards in view
  eventbus.emit("FrontPageScroller_stCh_cardStates", {
    cardStates,
    cards_inViewport: cardStates.flatMap((cardState) => (cardState.is_inView ? cardState.el : [])),
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
