// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import check_mediaEnabledGlobally from "../../../../../app/baseUtilities/check/check_mediaEnabledGlobally";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_FrontPageScroller_stCh_cardStates(ARGS) {
  // Setup...
  const { is_mobile } = this.state;
  const { id } = this.options;
  const { cards_inViewport, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  ////////////////////////////////
  // Behavior on mobile screens //
  ////////////////////////////////

  // Stop, if media is disabled globally...
  if (!check_mediaEnabledGlobally()) return;
  if (is_mobile) {
    // Play/Pause, when scroller card containing player comes into/out of view...
    const playerIsInViewport = cards_inViewport.some((card) => card.dataset.id === id);
    this.setState({ playback: playerIsInViewport ? "play" : "pause" });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
