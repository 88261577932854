// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["scroll area was scrolled"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_scrollArea_scroll(EVENTDATA) {
  // Setup...
  const { scrollArea, listItems } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Determine which item is in the center of the scroll area...
  const scrollAreaRect = scrollArea.getBoundingClientRect();
  const scrollAreaCenter = window.innerHeight / 2;
  const activeItem = Array.from(listItems).find((item) => {
    const itemRect = item.getBoundingClientRect();
    const itemCenter = itemRect.top + itemRect.height / 2;
    return itemCenter > scrollAreaCenter;
  });

  // Updt. active-item-ID state...
  this.setState({ activeItemID: activeItem.dataset.id });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
