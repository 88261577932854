// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_ScrollMenu_linkClick(ARGS) {
  // Setup...
  const { lenis } = this.modules;
  const { targetId, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Scroll lenis instance to target section...
  const scrollInstance = lenis?.instance;
  const targetSection = document.getElementById(targetId) || document.querySelector(`[data-scroll-id="${targetId}"]`);
  if (scrollInstance && targetSection) scrollInstance.scrollTo(targetSection, { offset: -100 });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
