// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "./lib/jGia/jGia/src/eventbus";

// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Modules //
import JGiaAppModule from "./modules/baseAppModules/jGia";
import swupAppModule from "./modules/baseAppModules/appMod-swup";
import LazyLoaderAppModule from "./modules/baseAppModules/LazyLoader";
import LinksAndButtons from "./modules/LinksAndButtons/LinksAndButtons";
import LetterSkewer from "./modules/LetterSkewer/LetterSkewer";

// Components //
import CookieBanner from "../site/plugins/component-cookie-banner/js/CookieBanner.js";
import EnsembleMemberList from "../site/plugins/component-ensemble-member-list/js/EnsembleMemberList.js";
import GoogleMap from "../site/plugins/component-google-map/js/GoogleMap.js";
import Calendar from "../site/plugins/component-calendar/js/Calendar.js";
import FrontPageNotifications from "../site/plugins/component-front-page-notifications/js/FrontPageNotifications.js";
import VideoPlayerBlock from "../site/plugins/content-component-video-player-block/js/VideoPlayerBlock.js";
import Slider from "../site/plugins/content-component-slider/js/Slider.js";
import ScrollArea from "../site/plugins/component-scroll-area/js/ScrollArea.js";
import Nav from "../site/plugins/component-nav/js/Nav.js";
import ScrollMenu from "../site/plugins/component-scroll-menu/js/ScrollMenu.js";
import Preloader from "../site/plugins/component-preloader/js/Preloader.js";
import FragmentPage from "../site/plugins/component-fragment-page/js/FragmentPage.js";
import FragmentPageBg from "../site/plugins/component-fragment-page-bg/js/FragmentPageBg.js";
import EventList from "../site/plugins/component-event-list/js/EventList.js";
import ScrollerImageCard from "../site/plugins/component-scroller-image-card/js/ScrollerImageCard.js";
import VideoPlayer from "../site/plugins/component-video-player/js/VideoPlayer.js";
import ScrollerEventCard from "../site/plugins/component-scroller-event-card/js/ScrollerEventCard.js";
import ScrollerVideoPlayer from "../site/plugins/component-scroller-video-player/js/ScrollerVideoPlayer.js";
import FrontPageScroller from "../site/plugins/component-front-page-scroller/js/FrontPageScroller.js";
import CalendarWidget from "../site/plugins/component-calendar-widget/js/CalendarWidget.js";
import LogoBanner from "../site/plugins/component-logo-banner/js/LogoBanner.js";

const availableComponents = [
  // Global
  { type: "Nav", script: Nav },
  { type: "Preloader", script: Preloader },
  { type: "FragmentPageBg", script: FragmentPageBg },

  // Local
  { type: "CookieBanner", script: CookieBanner },
  { type: "EnsembleMemberList", script: EnsembleMemberList },
  { type: "GoogleMap", script: GoogleMap },
  { type: "Calendar", script: Calendar },
  { type: "ScrollMenu", script: ScrollMenu },
  { type: "FrontPageNotifications", script: FrontPageNotifications },
  { type: "VideoPlayerBlock", script: VideoPlayerBlock },
  { type: "Slider", script: Slider },
  { type: "ScrollArea", script: ScrollArea },
  { type: "EventList", script: EventList },
  { type: "ScrollerImageCard", script: ScrollerImageCard },
  { type: "VideoPlayer", script: VideoPlayer },
  { type: "ScrollerEventCard", script: ScrollerEventCard },
  { type: "ScrollerVideoPlayer", script: ScrollerVideoPlayer },
  { type: "FrontPageScroller", script: FrontPageScroller },
  { type: "CalendarWidget", script: CalendarWidget },
  { type: "LogoBanner", script: LogoBanner },

  // Other
  { type: "FragmentPage", script: FragmentPage },
];

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_swup_page_load from "./eventHandlers/swup/eh_swup_page_load";

// Custom event handlers for swup module //
const swupEventHandlers = [
  {
    id: "page:load",
    handler: eh_swup_page_load,
  },
];

// Custom eventbus handlers for swup module //
const swupEventbusConfig = [];

// ————————————————————————————————————————— MODULE INIT. ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

(async () => {
  // Fetch app config...
  const appConfigRequest = await fetch("/app/config.json");
  const appConfig = await appConfigRequest.json();
  const giaConfig = appConfig?.gia;
  const swupConfig = appConfig?.swup;
  const globalComponentConfigs = appConfig?.globalComponents;
  const localComponentConfigs = appConfig?.localComponents;
  const LazyLoaderConfig = appConfig?.lazyLoader;
  const LetterSkewerConfig = appConfig?.LetterSkewer;

  // Extend module configs...
  swupConfig.eventbus = swupEventbusConfig;
  swupConfig.eventHandlers = swupEventHandlers;
  swupConfig.useNewJGiaPlugin = true;

  // Init. app modules...
  const jGiaAppModuleInstance = new JGiaAppModule(giaConfig, globalComponentConfigs, { availableComponents });
  const swupAppModuleInstance = new swupAppModule(swupConfig, giaConfig, { availableComponents }, localComponentConfigs);
  const LazyLoaderAppModuleInstance = new LazyLoaderAppModule(LazyLoaderConfig);
  const linksAndButtonsInstance = new LinksAndButtons();
  const LetterSkewerInstance = new LetterSkewer(LetterSkewerConfig);
  const appModules = [
    jGiaAppModuleInstance,
    swupAppModuleInstance,
    LazyLoaderAppModuleInstance,
    linksAndButtonsInstance,
    LetterSkewerInstance,
  ];

  try {
    console.log("%cInitialising app modules", "color: orange; font-weight: bold;");
    appModules.forEach((module) => {
      try {
        module.init();
      } catch (error) {
        console.error(`Module "${module.options.name}" failed to initialise.`);
        throw error;
      }
    });
    console.log("%cApp modules initialised", "color: green; font-weight: bold;");
  } catch (error) {
    console.error(error);
  }
})();

// ————————————————————————————————————————— GLOBAL STATE ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Create/Set global state object //
console.log("%cSetting global state", "color: orange; font-weight: bold;");
const globalState = { mediaIsEnabled: true, showSimpleLangContent: false };
localStorage.setItem("globalState", JSON.stringify(globalState));

// Mark doc. el. as touch device or no touch device //
const docEl = document.documentElement;
if (matchMedia("(pointer: coarse)").matches) docEl.setAttribute("data-touch-device", "true");
else docEl.setAttribute("data-touch-device", "false");

// ———————————————————————————————————————— GLOBAL EVENTS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_Nav_stChH_mediaToggleMode from "./eventbusHandlers/ebh_Nav_stCh_mediaToggleMode";
import ebh_Nav_stCh_showSimpleLangContent from "./eventbusHandlers/ebh_Nav_stCh_showSimpleLangContent";

// Event emissions //
window.addEventListener("resize", () => eventbus.emit("window_resize", { windowWidth: window.innerWidth }));
docEl.addEventListener("mouseleave", () => eventbus.emit("document_mouseLeave"));
docEl.addEventListener("click" || "touchstart", (e) => eventbus.emit("document_click", { eventData: e }));
docEl.addEventListener("mousemove", (e) => eventbus.emit("doc_mouseMove", { eventData: e }));

// Eventbus handling //
eventbus.on("Nav_stCh_mediaToggleMode", ebh_Nav_stChH_mediaToggleMode);
eventbus.on("Nav_stCh_showSimpleLangContent", ebh_Nav_stCh_showSimpleLangContent);

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
