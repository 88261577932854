// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ————————————————————————————————————————— PARENT CLASS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import BaseVideoPlayer from "../../component-base-video-player/js/BaseVideoPlayer";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// import ebh_Preloader_fileLoad from "./eventbusHandlers/ebh_Preloader_fileLoad.js";
// import ebh_FrontPageScroller_scroll from "./eventbusHandlers/ebh_FrontPageScroller_scroll.js";
// import ebh_FrontPageScroller_card_mouseEnter from "./eventbusHandlers/ebh_FrontPageScroller_card_mouseEnter.js";
// import ebh_swup_link_click from "./eventbusHandlers/ebh_swup_link_click.js";
// import ebh_swup_history_popstate from "./eventbusHandlers/ebh_swup_history_popstate.js";

// import stChH_source from "./stateChangeHandlers/stChH_source.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class VideoPlayerBlock extends BaseVideoPlayer {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      ...super.ref,
      playButton: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "VideoPlayerBlock",
      run_withLogs: "defaultValue",
      logStyles: "defaultValue",
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    // ...

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    // ...

    ////// State-change listeners //////
    ////////////////////////////////////

    // this.stChL_source = stChH_source.bind(this);

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    super.mount();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    super.unmount();

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    // ...
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  async init() {
    const { srcWillBePreloaded } = this.options;
    await super.init({
      init_source: !srcWillBePreloaded,
      init_playButton: true,
      init_fullscreenButton: true,
      init_soundButton: false,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    super.init_states();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    super.init_eventbus();
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    super.stateChange(CHANGES);

    ////////////// Change //////////////
    ////////////////////////////////////

    if ("change" in CHANGES) this.stChL_change(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default VideoPlayerBlock;
