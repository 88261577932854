// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

async function load_assets(MANIFEST) {
  // Setup...
  const { route, is_mobile } = this.state;
  this.logger("info", [`loading assets for route: ${route}`, MANIFEST], "action");

  // Toggle loading-state...
  this.setState({ loading: true });

  // Load assets...
  const loadQueue = this.modules.loadQueue.instance;
  if (!loadQueue) return this.logger("error", ["loadQueue not found"], "error", { inline: true });
  const { desktopFiles, mobileFiles } = MANIFEST;
  const sourcesToLoad = is_mobile ? mobileFiles : desktopFiles;
  if (!sourcesToLoad.length) {
    this.logger("warning", ["no assets to load"], "warning", { inline: true });
    return this.setState({ completed: true, loading: false });
  }
  loadQueue.loadManifest(sourcesToLoad);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default load_assets;
