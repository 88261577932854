// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_Nav_stChH_mediaToggleMode(ARGS) {
  // Setup...
  const { mediaToggleMode, _name } = ARGS;

  // Set show-media attr. on doc...
  const doc = document.documentElement;
  doc.setAttribute("data-show-media", mediaToggleMode === "on");

  // Set global state...
  const globalState = JSON.parse(localStorage.getItem("globalState"));
  globalState.mediaIsEnabled = mediaToggleMode === "on";
  localStorage.setItem("globalState", JSON.stringify(globalState));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
