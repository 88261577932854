// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_FrontPageScroller_footerSectionInViewChange(ARGS) {
  // Setup...
  const { footerSection_is_inView, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Set states...
  // ↳ Toggle visibility (mode)
  this.setState({ mode: footerSection_is_inView ? "inactive" : "active" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
