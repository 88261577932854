// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_openButton_click() {
  // Setup...
  const { is_mobile } = this.state;
  this.logger("event", ["open-button was clicked"], "event", { inline: true });

  // Toggle visibility of content...
  if (!is_mobile) return;
  this.setState({ contentVisibility: "visible" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_openButton_click;
