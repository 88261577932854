// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_progress(EVENTDATA) {
  // Setup...
  const { tolog_events } = this.options;
  const { progress } = EVENTDATA;
  if (tolog_events.find((e) => e.name === "progress")?.log === "true")
    this.logger("event", [`queue progress: ${progress * 100}% `], "event", { inline: true });

  // Updt. progress in state...
  this.setState({ progress: progress * 100 });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_progress;
