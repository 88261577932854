// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * @description Function to handle changes to the mode state.
 *              The mode may be changed to one of the following possible states:
 *
 *              - "init"     → the component’s init. functions are about to be called
 *
 *              - "ready"    → all component’s init. functions have been called, but it is inactive
 *                           → interactions are disabled (no pointer events on entire component el.)
 *                           → scrolling is disabled (scroll instance is stopped)
 *
 *              - "active"   → the component is active;
 *                           → interactions are enabled
 *                           → scrolling is enabled
 *
 *              - "inactive" → interactions are disabled
 *                           → scroll is disabled
 *
 *              The mode state changes at the occurence of the following events:
 *
 *              - "swup_link_click" → "inactive" when navigating to a fragment page,
 *                                  → "active" when navigating to the front page
 *
 * @param {Object} CHANGES - Object containing the changes to the mode state.
 **/

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`mode:`, CHANGE],
  error_noStateChange: () => ["No change to mode state provided."],
  error_invalidStateValue: (VALUE) => [`Invalid state value: ${VALUE}`],
};

const possibleStateValues = ["init", "ready", "active", "inactive"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mode(CHANGES) {
  if (!("mode" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error");
  if (!possibleStateValues.includes(CHANGES.mode))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.mode), "error");

  // Setup...
  const { mode } = CHANGES;
  const { lenis } = this.modules;
  this.logger("state-change", loggerMsgs.stateChange(mode), "default", { inline: true });

  // Toggle active attr. on component el...
  this.element.setAttribute("data-is-active", mode == "active");

  // Disable/enable scroll...
  const scrollInstance = lenis.instance;
  if (scrollInstance) {
    if (mode == "inactive") scrollInstance.stop();
    if (mode == "active") scrollInstance.start();
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
