// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

async function get_preloadManifest(ROUTE) {
  const routeDataRequest = await fetch(`/${ROUTE}.json`);

  // Stop, if no manifest found...
  if (routeDataRequest.status === 404) {
    this.logger("info", [`no preload manifest found for route ${ROUTE}`], "error");
    return false;
  }

  const routeData = await routeDataRequest.json();
  return routeData.manifest;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default get_preloadManifest;
