// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_document_click(ARGS) {
  // Stop, if not on mobile...
  if (!this.state.is_mobile) return;

  // Setup...
  const { contentVisibility } = this.state;
  const { eventData, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Close menu if click occured outside of menu...
  if (this.element.contains(eventData.target) || contentVisibility === "hidden") return;
  this.setState({ contentVisibility: "hidden" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
