// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_time(CHANGES) {
  // Setup...
  const { sendTimeUpdates } = this.state;
  // this.logger("state-change", [`time: ${time}`, `progress: ${progress}`]);

  // Notify other components...
  // ↳ Cursor → updt. cursor text
  if (!sendTimeUpdates) return;
  const { time, progress } = CHANGES;
  eventbus.emit("VideoPlayer_timeUpdt", { time, progress });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_time;
