// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import get_preloadManifest from "./utilities/get_preloadManifest";
import load_assets from "./utilities/load_assets";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_loader from "./init/init_loader.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import stChH_progress from "./stateChangeHandlers/stChH_progress.js";
import stChH_hidden from "./stateChangeHandlers/stChH_hidden.js";
import stChH_completed from "./stateChangeHandlers/stChH_completed.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class Preloader extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = { progressBar: null };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "Preloader",
      initRoute: "defaultValue",
      ...options,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.get_preloadManifest = get_preloadManifest.bind(this);
    this.load_assets = load_assets.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_loader = init_loader.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      loadQueue: { instance: this.init_loader() },
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    // ...

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_progress = stChH_progress.bind(this);
    this.stChL_hidden = stChH_hidden.bind(this);
    this.stChL_completed = stChH_completed.bind(this);

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    // ...
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  async init() {
    this.init_states();
    this.init_eventbus();

    // Load assets of init. route...
    this.logger("info", ["loading manifest of init. route"], "action", { inline: true });
    const manifest = await this.get_preloadManifest(this.options.initRoute);
    const manifest_hasFiles = manifest && (manifest.mobileFiles?.length || manifest.desktopFiles?.length);
    if (manifest_hasFiles) this.load_assets(manifest);
    else {
      this.logger("warning", ["no assets to load"], "warning", { inline: true });
      this.setState({ completed: true, loading: false });
    }
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({ route: this.options.initRoute, is_mobile: window.innerWidth < 640 });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    // ...
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    ///////////// progress /////////////
    ////////////////////////////////////

    if ("progress" in CHANGES) this.stChL_progress(CHANGES);

    //////////// completed ////////////
    ////////////////////////////////////

    if ("completed" in CHANGES) this.stChL_completed(CHANGES);

    ////////////// hidden //////////////
    ////////////////////////////////////

    if ("hidden" in CHANGES) this.stChL_hidden(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default Preloader;
