// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../baseUtilities/logger";
import set_state from "../../baseUtilities/state/set_state";
import skewLetters from "./utilities/skewLetters";
import unskewLetters from "./utilities/unskewLetters";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_textsToSkew from "./init/init_textsToSkew";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_page_view from "./eventbusHandlers/ebh_swup_page_view";
import ebh_FrontPageScroller_card_mouseEnter from "./eventbusHandlers/ebh_FrontPageScroller_card_mouseEnter";
import ebh_FrontPageScroller_card_mouseLeave from "./eventbusHandlers/ebh_FrontPageScroller_card_mouseLeave";
import ebh_FrontPageScroller_stCh_cardStates from "./eventbusHandlers/ebh_FrontPageScroller_stCh_cardStates";
import ebh_Nav_stCh_mediaToggleMode from "./eventbusHandlers/ebh_Nav_stCh_mediaToggleMode";
import ebh_Calendar_stCh_activeMonth from "./eventbusHandlers/ebh_Calendar_stCh_activeMonth";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class LetterSkewer {
  //////////////////////////////// Constructor /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      logs: {
        run_withLogs: true,
        logStyles: {
          default: "#4361BC",
          action: "#c7d0ff",
          event: "#97a5ce",
          warning: "#ffaf00",
          error: "#ff3232",
        },
      },
      linkElementSkewing: {
        maxDegree: 30,
        minDegree: -30,
      },
    },
    OPTIONS
  ) {
    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      name: "LetterSkewer",
      run_withLogs: CONFIG?.logs?.run_withLogs,
      logStyles: CONFIG?.logs?.logStyles,
      //////
      linkElementSkewing: CONFIG?.linkElementSkewing || {
        maxDegree: 30,
        minDegree: -30,
      },
    };

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);
    this.skewLetters = skewLetters.bind(this);
    this.unskewLetters = unskewLetters.bind(this);

    /////////////////////// Init. f() //////////////////////////
    ////////////////////////////////////////////////////////////

    this.init_textsToSkew = init_textsToSkew.bind(this);

    //////////////////// Eventbus listeners ////////////////////
    ////////////////////////////////////////////////////////////

    this.ebl_swup_page_view = ebh_swup_page_view.bind(this);
    this.ebl_FrontPageScroller_card_mouseEnter = ebh_FrontPageScroller_card_mouseEnter.bind(this);
    this.ebl_FrontPageScroller_card_mouseLeave = ebh_FrontPageScroller_card_mouseLeave.bind(this);
    this.ebl_FrontPageScroller_stCh_cardStates = ebh_FrontPageScroller_stCh_cardStates.bind(this);
    this.ebl_Nav_stCh_mediaToggleMode = ebh_Nav_stCh_mediaToggleMode.bind(this);
    this.ebl_Calendar_stCh_activeMonth = ebh_Calendar_stCh_activeMonth.bind(this);
  }

  ////////////////////////////// State management //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState instead."], "warning");
    this._state = state;
  }

  stateChange(CHANGES) {
    if (this.options.run_withLogs) console.log("stateChange", CHANGES);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.logger("init", ["module"], "default", { inline: true });
    this.init_state();
    this.init_eventbus();
    this.init_textsToSkew();
    //////
    this.setState({ initialised: true, mode: "on" });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_state() {
    this.logger("init", ["state"], "default", { inline: true });
    this.setState({
      mode: "initialising",
      runningSkewAnims: [],
      is_mobile: window.innerWidth <= 640,
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    // Listener registration //
    eventbus.on("swup_page_view", this.ebl_swup_page_view);
    eventbus.on("FrontPageScroller_card_mouseEnter", this.ebl_FrontPageScroller_card_mouseEnter);
    eventbus.on("FrontPageScroller_card_mouseLeave", this.ebl_FrontPageScroller_card_mouseLeave);
    eventbus.on("FrontPageScroller_stCh_cardStates", this.ebl_FrontPageScroller_stCh_cardStates);
    eventbus.on("Nav_stCh_mediaToggleMode", this.ebl_Nav_stCh_mediaToggleMode);
    eventbus.on("Calendar_stCh_activeMonth", this.ebl_Calendar_stCh_activeMonth);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default LetterSkewer;
