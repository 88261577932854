// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_playButton_click from "../eventHandlers/eh_playButton_click";
import eh_playButton_mouseEnter from "../eventHandlers/eh_playButton_mouseEnter";
import eh_playButton_mouseLeave from "../eventHandlers/eh_playButton_mouseLeave";

// ****************************************************************************** //
// ****************************************************************************** //

function init_playButton(BUTTONELEMENT = this.ref.playButton) {
  // Setup...
  this.logger("init", ["play button"], "action", { inline: true });

  // Stop, if no playbutton el. available
  if (!BUTTONELEMENT) {
    this.logger("info", ["no valid playbutton element available"], "warning");
    return false;
  }

  // Listen for click & mouse interactions on play-button...
  BUTTONELEMENT.addEventListener("click", eh_playButton_click.bind(this));
  BUTTONELEMENT.addEventListener("mouseenter", eh_playButton_mouseEnter.bind(this));
  BUTTONELEMENT.addEventListener("mouseleave", eh_playButton_mouseLeave.bind(this));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_playButton;
