// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_Nav_stCh_showSimpleLangContent(ARGS) {
  // Setup...
  const { showSimpleLangContent, _name } = ARGS;

  // Set show-simple-lang-content attr. on doc...
  const doc = document.documentElement;
  doc.setAttribute("data-show-simple-lang-content", showSimpleLangContent);

  // Set global state...
  const globalState = JSON.parse(localStorage.getItem("globalState"));
  globalState.showSimpleLangContent = showSimpleLangContent;
  localStorage.setItem("globalState", JSON.stringify(globalState));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
