// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_acceptButton_click from "../eventHandlers/eh_acceptButton_click";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_acceptButton() {
  // Setup...
  const refs = this.ref;
  this.logger("init", ["accept-button"], "action", { inline: true });

  // Listen to click on accept button...
  const acceptButton = refs.acceptButton;
  acceptButton.addEventListener("click", eh_acceptButton_click.bind(this));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_acceptButton;
