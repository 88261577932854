// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_simpleLangSwitchHidden(CHANGES) {
  // Setup...
  const { simpleLangSwitch } = this.ref;
  const { simpleLangSwitchHidden } = CHANGES;
  this.logger("state-change", [`simple lang. switch is hidden: ${simpleLangSwitchHidden}`], "default", { inline: true });

  // Show/hide simple lang. switch element...
  simpleLangSwitch.setAttribute("data-is-hidden", simpleLangSwitchHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_simpleLangSwitchHidden;
