// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_listItems_mouseEnter from "../eventHandlers/eh_listItems_mouseEnter.js";
import eh_listItems_mouseLeave from "../eventHandlers/eh_listItems_mouseLeave.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. listItems"],
  initSuccess: ["init. listItems", "init. success"],
  noValidDOMEl: ["init. listItems", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_listItems() {
  // Setup...
  const { listItems } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (listItems.every((el) => !this.validate_refEL(el))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to mouse events on list items
  listItems.forEach((el) => {
    const linkEl = el.querySelector("a");
    if (!linkEl) return;
    linkEl.addEventListener("mouseenter", eh_listItems_mouseEnter.bind(this, el));
    // linkEl.addEventListener("mouseleave", eh_listItems_mouseLeave.bind(this, el));
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
