// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_openButton_mouseEnter() {
  // Setup...
  const { is_mobile } = this.state;
  this.logger("event", ["mouse entered open-button"], "event", { inline: true });

  // Set states...
  // ↳ Toggle visibility of content
  if (is_mobile) return;
  this.setState({ contentVisibility: "visible" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_openButton_mouseEnter;
