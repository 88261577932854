// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_source(CHANGES) {
  // Setup...
  const { hidden: player_is_hidden } = this.state;
  const videoJsInstance = this.modules.videojs.instance;
  const { source } = CHANGES;
  this.logger("state-change", [`new source: ${source}`], "default", { inline: true });

  // Show loading cover...
  this.setState({ loadingCover: { visibility: "visible" } });

  // Hide player element, if not already hidden, then update src. & show player element...
  if (!player_is_hidden) {
    this.logger("info", [`Hiding player el.`], "action");
    this.element.setAttribute("data-is-active", false);
    //           ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    //           ↑ Hide player el. via data-attr. i.o. state-change to continue using
    //             hidden-state to trigger Project List comp. state-change

    setTimeout(() => {
      this.logger("info", [`Updating src.`], "action");
      videoJsInstance.src({ src: source, type: "video/mp4" });

      this.logger("info", [`Showing player el.`], "action");
      this.element.setAttribute("data-is-active", true);
    }, 300);
    // ^^^
    // ↑ Wait for player el. to be hidden before updating src.
  }

  // If player is hidden, update src. immediately & show player element...
  else {
    this.logger("info", [`Updating src.`], "action");
    videoJsInstance.src({ src: source, type: "video/mp4" });

    this.logger("info", [`Showing player el.`], "action");
    this.setState({ hidden: false });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_source;
