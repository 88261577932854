// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../../../app/baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function positionNotifications(
  notifications = this.ref.notifications,
  CONFIG = {
    originEl: document.querySelector("[g-ref='Nav:logo']"),
    topDistanceToOrigin: 0,
  }
) {
  // Setup...
  const { originEl = document.querySelector("[g-ref='Nav:logo']"), topDistanceToOrigin = 0 } = CONFIG;
  this.logger("info", ["positioning notification elements"], "action", { inline: true });

  // Determ. starting position/origin...
  const { bottom } = originEl ? originEl.getBoundingClientRect() : { bottom: 0 };
  let origin = { t: bottom + topDistanceToOrigin, l: 8 };

  // Position each notification element, starting from top/left of origin
  const gap = 10;
  notifications.forEach((notif) => {
    // Position...
    const { t, l } = origin;
    const { height } = notif.getBoundingClientRect();
    updt_styleProp(notif, "--top", `${t}px`);
    updt_styleProp(notif, "--left", `${l}px`);

    // Update origin...
    origin.t += height + gap;
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
