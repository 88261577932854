// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import set_menuSize from "./utilities/set_menuSize.js";

function check_isTouchDevice() {
  return matchMedia("(pointer: coarse)").matches;
}

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_logo from "./init/init_logo.js";
import init_menu from "./init/init_menu.js";
import init_menuButton from "./init/init_menuButton.js";
import init_simpleLangSwitch from "./init/init_simpleLangSwitch.js";
import init_backToTopButton from "./init/init_backToTopButton.js";
import init_fragmPageCloseButton from "./init/init_fragmPageCloseButton.js";
import init_mediaToggle from "./init/init_mediaToggle.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js";
import ebh_swup_page_view from "./eventbusHandlers/ebh_swup_page_view.js";
import ebh_doc_mouseMove from "./eventbusHandlers/ebh_doc_mouseMove.js";
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_document_mouseLeave from "./eventbusHandlers/ebh_document_mouseLeave.js";
import ebh_FrontPageScroller_scrollDirChange from "./eventbusHandlers/ebh_FrontPageScroller_scrollDirChange.js";
import ebh_FrontPageScroller_footerSectionInViewChange from "./eventbusHandlers/ebh_FrontPageScroller_footerSectionInViewChange.js";
import ebh_ScrollArea_scrollDirChange from "./eventbusHandlers/ebh_ScrollArea_scrollDirChange";
import ebh_ScrollArea_footerInViewChange from "./eventbusHandlers/ebh_ScrollArea_footerInViewChange.js";
import ebh_ScrollArea_reachedTop from "./eventbusHandlers/ebh_ScrollArea_reachedTop.js";
import ebh_ScrollArea_reachedBottom from "./eventbusHandlers/ebh_ScrollArea_reachedBottom.js";

import stChH_menuSize from "./stateChangeHandlers/stChH_menuSize.js";
import stChH_activeMenuLink from "./stateChangeHandlers/stChH_activeMenuLink.js";
import stChH_mediaToggleState from "./stateChangeHandlers/stChH_mediaToggleState.js";
import stChH_menuOpen from "./stateChangeHandlers/stChH_menuOpen.js";
import stChH_simpleLangSwitchHidden from "./stateChangeHandlers/stChH_simpleLangSwitchHidden.js";
import stChH_backToTopButtonHidden from "./stateChangeHandlers/stChH_backToTopButtonHidden.js";
import stChH_fragmPageCloseButtonHidden from "./stateChangeHandlers/stChH_fragmPageCloseButtonHidden.js";
import stChH_fragmPageCloseButtonActive from "./stateChangeHandlers/stChH_fragmPageCloseButtonActive.js";
import stChH_showSimpleLangContent from "./stateChangeHandlers/stChH_showSimpleLangContent.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class Nav extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      logo: null,
      menu: null,
      menuButton: null,
      menuCloseButton: null,
      menuLinkWrapper: null,
      menuLinks: [],
      langLinks: [],
      simpleLangSwitch: null,
      backToTopButton: null,
      fragmPageCloseButton: null,
      mediaToggle: null,
      mediaToggleCrossOutSVG: null,
      mediaToggleEyeball: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "Nav",
      initParentPageURL: "defaultValue",
      ...options,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.set_menuSize = set_menuSize.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_mediaToggle = init_mediaToggle.bind(this);
    this.init_backToTopButton = init_backToTopButton.bind(this);
    this.init_logo = init_logo.bind(this);
    this.init_menu = init_menu.bind(this);
    this.init_menuButton = init_menuButton.bind(this);
    this.init_simpleLangSwitch = init_simpleLangSwitch.bind(this);
    this.init_fragmPageCloseButton = init_fragmPageCloseButton.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_doc_mouseMove = ebh_doc_mouseMove.bind(this);
    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);
    this.ebl_ScrollArea_reachedBottom = ebh_ScrollArea_reachedBottom.bind(this);
    this.ebl_swup_page_view = ebh_swup_page_view.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);
    this.ebh_document_mouseLeave = ebh_document_mouseLeave.bind(this);

    this.ebl_FrontPageScroller_scrollDirChange = ebh_FrontPageScroller_scrollDirChange.bind(this);
    this.ebl_FrontPageScroller_footerSectionInViewChange = ebh_FrontPageScroller_footerSectionInViewChange.bind(this);

    this.ebl_ScrollArea_scrollDirChange = ebh_ScrollArea_scrollDirChange.bind(this);
    this.ebl_ScrollArea_footerInViewChange = ebh_ScrollArea_footerInViewChange.bind(this);
    this.ebl_ScrollArea_reachedTop = ebh_ScrollArea_reachedTop.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_showSimpleLangContent = stChH_showSimpleLangContent.bind(this);
    this.stChL_menuSize = stChH_menuSize.bind(this);
    this.stChL_menuOpen = stChH_menuOpen.bind(this);
    this.stChL_activeMenuLink = stChH_activeMenuLink.bind(this);
    this.stChL_mediaToggleState = stChH_mediaToggleState.bind(this);
    this.stChL_backToTopButtonHidden = stChH_backToTopButtonHidden.bind(this);
    this.stChL_simpleLangSwitchHidden = stChH_simpleLangSwitchHidden.bind(this);
    this.stChL_fragmPageCloseButtonHidden = stChH_fragmPageCloseButtonHidden.bind(this);
    this.stChL_fragmPageCloseButtonActive = stChH_fragmPageCloseButtonActive.bind(this);

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("doc_mouseMove", this.ebl_doc_mouseMove);

    eventbus.off("swup_page_load", this.ebl_swup_page_load);
    eventbus.off("swup_page_view", this.ebl_swup_page_view);
    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("document_mouseLeave", this.ebh_document_mouseLeave);

    eventbus.off("FrontPageScroller_scrollDirChange", this.ebl_FrontPageScroller_scrollDirChange);
    eventbus.off("FrontPageScroller_footerSectionInViewChange", this.ebl_FrontPageScroller_footerSectionInViewChange);

    eventbus.off("ScrollArea_scrollDirChange", this.ebl_ScrollArea_scrollDirChange);
    eventbus.off("ScrollArea_footerInViewChange", this.ebl_ScrollArea_footerInViewChange);
    eventbus.off("ScrollArea_reachedTop", this.ebl_ScrollArea_reachedTop);
    eventbus.off("ScrollArea_reachedBottom", this.ebl_ScrollArea_reachedBottom);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_mediaToggle();
    this.init_states();
    this.init_logo();
    this.init_menu();
    this.init_menuButton();
    this.init_simpleLangSwitch();
    this.init_backToTopButton();
    this.init_fragmPageCloseButton();
    this.init_eventbus();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({
      is_mobile: check_isTouchDevice(),
      mediaToggleState: { mode: "on", is_active: true },
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    eventbus.on("doc_mouseMove", this.ebl_doc_mouseMove);
    this.logger("init", ["eventbus"], "action", { inline: true });

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("swup_page_load", this.ebl_swup_page_load);
    eventbus.on("swup_page_view", this.ebl_swup_page_view);
    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("document_mouseLeave", this.ebh_document_mouseLeave);

    eventbus.on("FrontPageScroller_scrollDirChange", this.ebl_FrontPageScroller_scrollDirChange);
    eventbus.on("FrontPageScroller_footerSectionInViewChange", this.ebl_FrontPageScroller_footerSectionInViewChange);

    eventbus.on("ScrollArea_scrollDirChange", this.ebl_ScrollArea_scrollDirChange);
    eventbus.on("ScrollArea_footerInViewChange", this.ebl_ScrollArea_footerInViewChange);
    eventbus.on("ScrollArea_reachedTop", this.ebl_ScrollArea_reachedTop);
    eventbus.on("ScrollArea_reachedBottom", this.ebl_ScrollArea_reachedBottom);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    ////// showSimpleLangContent //////
    ////////////////////////////////////

    if ("showSimpleLangContent" in CHANGES) this.stChL_showSimpleLangContent(CHANGES);

    ///////// mediaToggleState /////////
    ////////////////////////////////////

    if ("mediaToggleState" in CHANGES) this.stChL_mediaToggleState(CHANGES);

    //// fragmPageCloseButtonActive ////
    ////////////////////////////////////

    if ("fragmPageCloseButtonActive" in CHANGES) this.stChL_fragmPageCloseButtonActive(CHANGES);

    //// fragmPageCloseButtonHidden ////
    ////////////////////////////////////

    if ("fragmPageCloseButtonHidden" in CHANGES) this.stChL_fragmPageCloseButtonHidden(CHANGES);

    ////// backToTopButtonHidden //////
    ////////////////////////////////////

    if ("backToTopButtonHidden" in CHANGES) this.stChL_backToTopButtonHidden(CHANGES);

    ////// simpleLangSwitchHidden //////
    ////////////////////////////////////

    if ("simpleLangSwitchHidden" in CHANGES) this.stChL_simpleLangSwitchHidden(CHANGES);

    ///////////// menuSize /////////////
    ////////////////////////////////////

    if ("menuSize" in CHANGES) this.stChL_menuSize(CHANGES);

    ///////////// menuOpen /////////////
    ////////////////////////////////////

    if ("menuOpen" in CHANGES) this.stChL_menuOpen(CHANGES);

    ////////// activeMenuLink //////////
    ////////////////////////////////////

    if ("activeMenuLink" in CHANGES) this.stChL_activeMenuLink(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default Nav;
