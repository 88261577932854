// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMsgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_ScrollArea_scroll(ARGS) {
  // Setup...
  const { listItems } = this.ref;
  const { _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  // Determine which item is in the center of the viewport...
  const scrollAreaCenter = window.innerHeight / 2;
  const activeItem = Array.from(listItems).find((item) => {
    const itemRect = item.getBoundingClientRect();
    const itemCenter = itemRect.top + itemRect.height / 2;
    return itemCenter > scrollAreaCenter;
  });

  // Updt. active-item-ID state...
  this.setState({ activeItemID: activeItem?.dataset.id });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
