// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Lenis from "@studio-freight/lenis";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_scroll from "../eventHandlers/eh_scroll";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_lenis() {
  // Setup...
  const { scrollArea, scrollAreaContent } = this.ref;
  this.logger("init", ["Lenis scroll instance"], "action", { inline: true });

  ///////////////////////////
  // Create Lenis instance //
  ///////////////////////////

  const lenisScrollInstance = new Lenis({
    wrapper: scrollArea,
    content: scrollAreaContent,
    orientation: "vertical",
    eventsTarget: window,
  });

  ///////////////////////////
  // Scroll event handling //
  ///////////////////////////

  lenisScrollInstance.on("scroll", eh_scroll.bind(this));

  ////////////////////////////
  // Init. & store instance //
  ////////////////////////////

  function raf(time) {
    lenisScrollInstance.raf(time);
    requestAnimationFrame(raf);
  }
  requestAnimationFrame(raf);

  // Stop instance (will be started when preloading completes)...
  // lenisScrollInstance.stop();

  return lenisScrollInstance;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_lenis;
