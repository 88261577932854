// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_fragmPageCloseButton_click() {
  // Setup...
  const { initParentPageURL } = this.options;
  this.logger("event", ["fragm. page close-button was clicked"], "event", { inline: true });

  // Notify other components/modules via eventbus...
  eventbus.emit("Nav_fragmPageCloseButton_click", { parentPageURL: initParentPageURL });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_fragmPageCloseButton_click;
