// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ———————————————————————————————n—————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_text_mouseEnter from "../eventHandlers/eh_text_mouseEnter";
import eh_text_mouseLeave from "../eventHandlers/eh_text_mouseLeave";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  noValidDOMEl: ["init. texts to skew", "no valid DOM elements provided"],
  toBeSkewedOnSpecificEvent: ["init. texts to skew", "text intended to be skewed on specific event"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_textsToSkew() {
  // Setup...
  this.logger("init", ["texts to skew"], "action", { inline: true });

  // Find applicable text elements...
  const textElements = document.querySelectorAll("[data-rzt-text-to-skew='uninitialized']");
  if (!textElements || textElements.length < 1) {
    this.logger("init", loggerMsgs.noValidDOMEl, "warning", { inline: true });
    return;
  }

  // Listen to mouse events on links...
  textElements.forEach((el) => {
    // Stop, if text is intended to be skewed on an event...
    if (el.dataset.rztTextToSkewOnEvent === "true") {
      this.logger("init", loggerMsgs.toBeSkewedOnSpecificEvent, "warning", { inline: true });
      return;
    }

    // Listen to mouse events on text...
    el.addEventListener("mouseenter", eh_text_mouseEnter.bind(this, { textElement: el }));
    el.addEventListener("mouseleave", eh_text_mouseLeave.bind(this, { textElement: el }));

    // Mark text as initialized...
    el.setAttribute("data-rzt-text-to-skew", "initialized");
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_textsToSkew;
