// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_change from "../eventHandlers/eh_change.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  noValidDOMEl: ["init. flickity instance", "no valid DOM element for instance wrapper provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_flickity() {
  // Setup...
  const { slidesWrapper } = this.ref;
  this.logger("init", ["flickity"], "action", { inline: true });

  // Guard...
  if (!slidesWrapper || slidesWrapper instanceof HTMLElement === false) {
    this.logger("error", loggerMsgs.noValidDOMEl, "error", { inline: true });
    return;
  }

  // Create Flickity instance...
  const flickityInstance = new Flickity(slidesWrapper, {
    wrapAround: true,
    draggable: window.innerWidth < 640,
    prevNextButtons: false,
    pageDots: false,
    imagesLoaded: true,
    lazyLoad: 1,
    //////
    on: {
      ready: this.init_buttons,
      change: eh_change.bind(this),
    },
  });

  return flickityInstance;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_flickity;
