// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";
import videojs from "video.js";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_canPlay from "../eventHandlers/eh_canPlay";
import eh_playing from "../eventHandlers/eh_playing";
import eh_pausing from "../eventHandlers/eh_pausing";
import eh_error from "../eventHandlers/eh_error";
import eh_suspend from "../eventHandlers/eh_suspend";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const availableEvents = [
  "canplay",
  "playing",
  "pause",
  "error",
  "suspend",
  "loadedmetadata",
  "fullscreenchange",
  "timeupdate",
  "dispose",
];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_player(EVENTHANDLERS = []) {
  return new Promise((resolve) => {
    // Setup...
    const { media, playButton, soundButton, closeButton } = this.ref;
    const { controls, muted, loop, src_poster } = this.options;

    this.logger("init", ["videoJs player instance"], "action", { inline: true });

    /////////////////////////////////
    // Create video-js instance... //
    /////////////////////////////////

    const playerInstance = videojs(media, {
      poster: src_poster,
      controls: controls,
      controlBar: {
        pictureInPictureToggle: false,
        volumePanel: false,
        remainingTimeDisplay: false,
      },
      muted: muted,
      loop: loop,
      fluid: true,
      inactivityTimeout: 0,
      userActions: {
        click: false,
        doubleClick: false,
      },
    });

    ////////////////////
    // Event handling //
    ////////////////////

    playerInstance.on("playing", eh_playing.bind(this));
    playerInstance.on("pause", eh_pausing.bind(this));
    playerInstance.on("error", eh_error.bind(this));
    playerInstance.on("suspend", eh_suspend.bind(this));

    // Assign custom event handlers...
    EVENTHANDLERS.forEach((handler) => {
      const { event, handler: handlerFn } = handler;
      if (event === "canplay") return; // Skip 'canplay' event (handled below)...
      this.logger("init", [`custom event handler for '${event}' event`], "action", { inline: true });

      // Check if event exists on video-js instance...
      if (!availableEvents.includes(event)) {
        this.logger("error", [`event '${event}' does not exist on video-js instance`], "action", { inline: true });
        return;
      }

      // Assign handler...
      playerInstance.on(event, handlerFn.bind(this));
    });

    // Updt. duration-state when video meta data has been loaded...
    playerInstance.on("loadedmetadata", () => this.setState({ duration: playerInstance.duration() }));

    // Assign event handler passed to init. f() if available...
    const eh_canPlay_custom = EVENTHANDLERS.find((handler) => handler.event === "canplay");
    if (eh_canPlay_custom) {
      this.logger("init", ["custom event handler for 'canplay' event"], "action", { inline: true });
      playerInstance.on("canplay", eh_canPlay_custom.handler);
    }
    // Otherwise, assign default event handler...
    else {
      this.logger("init", ["default event handler for 'canplay' event"], "action", { inline: true });
      playerInstance.on("canplay", eh_canPlay.bind(this));
    }

    // Pause video when exiting fullscreen...
    playerInstance.on("fullscreenchange", () => {
      if (!playerInstance.isFullscreen()) this.setState({ playback: "pause", fullscreen: false });
    });

    // Listen for playback time updt. to save time/progress to state & updt. cursor text...
    playerInstance.on("timeupdate", () =>
      this.setState({
        progress: playerInstance.currentTime() / playerInstance.duration(),
        time: playerInstance.currentTime(),
      })
    );

    // Notify when player instance is removed from DOM & memory...
    playerInstance.on("dispose", () => this.logger("info", ["player instance was removed from DOM & memory"], "event"));

    // Resolve init. f() promise...
    playerInstance.on("ready", () => resolve(playerInstance));

    // Notify other components when interacting w/ control elements...
    const progressBarWrapper = this.element.querySelector(".vjs-control-bar");
    const controlElements = [progressBarWrapper, playButton, soundButton, closeButton];
    controlElements.forEach((el) => {
      if (!el) return;
      el.addEventListener("mouseenter", () => eventbus.emit(`VideoPlayer_controls_mouseEnter`));
      el.addEventListener("mouseleave", () => eventbus.emit(`VideoPlayer_controls_mouseLeave`));
    });
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_player;
