// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_notificationStates(CHANGES) {
  // Setup...
  const { is_mobile } = this.state;
  const { notificationStates } = CHANGES;
  const { notifications } = this.ref;
  this.logger("state-change", [`notification states:`, notificationStates]);

  // Update notification visibility (staggered)...
  let delay = 0;
  notifications.forEach((notifEl) => {
    const notifState = notificationStates.find((notifState) => notifState.id === notifEl.dataset.id);
    const { visibility } = notifState;
    notifEl.setAttribute("data-is-hidden", visibility === "hidden");
    setTimeout(() => {
      notifEl.setAttribute("data-is-offscreen", visibility === "offscreen");
    }, delay);
    delay += 60;
  });

  // Update notification positions (of visible notifications)...
  const originEl = is_mobile ? document.querySelector("[g-ref='Nav:menuButton']") : document.querySelector("[g-ref='Nav:logo']");
  const visibleNotifications = notifications.filter((notifEl) => notifEl.dataset.isHidden === "false");
  this.positionNotifications(visibleNotifications, { originEl, topDistanceToOrigin: is_mobile ? 8 : 0 });
  //                                                           ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  //                                                           ↑ Ensures proper distance btw. 1st notification
  //                                                             and mobile menu button
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_notificationStates;
