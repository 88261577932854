// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_text_mouseLeave(ARGS, EVENTDATA) {
  // Stop, if module instance is turned off or on mobile...
  if (this.state.mode == "off" || this.state.is_mobile) return;

  // Setup...
  const { runningSkewAnims } = this.state;
  const { textElement } = ARGS;
  this.logger("event", ["mouse entered text to be skewed"], "event", { inline: true });

  // Stop animation for text...
  const { rztTextToSkewId } = textElement.dataset;
  const skewAnim_text = runningSkewAnims.find((skewAnim) => skewAnim.id == rztTextToSkewId);
  if (!skewAnim_text) {
    this.logger("info", ["stopping skew anim. for text", "no skewAnim found for text"], "warning", { inline: true });
    return;
  }
  clearInterval(skewAnim_text.interval);

  // Remove stopped animation from state...
  this.setState({ runningSkewAnims: runningSkewAnims.filter((skewAnim) => skewAnim.id != rztTextToSkewId) });

  // Unskew letters...
  this.unskewLetters(skewAnim_text.element);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_text_mouseLeave;
