// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_text_mouseEnter(ARGS, EVENTDATA) {
  // Stop, if module instance is turned off or on mobile...
  if (this.state.mode == "off" || this.state.is_mobile) return;

  // Setup...
  const { runningSkewAnims } = this.state;
  const { textElement } = ARGS;
  this.logger("event", ["mouse entered text to be skewed"], "event", { inline: true });

  ////////////////////////////
  // Text skewing animation //
  ////////////////////////////

  // Stop, if animation for text is already running...
  const { rztTextToSkewId } = textElement.dataset;
  const skewAnim_text = runningSkewAnims.find((anim) => anim.id == rztTextToSkewId);
  if (skewAnim_text) {
    this.logger("info", ["skewing text on eventbus call", "animation already running"], "warning", { inline: true });
    return;
  }

  // Start skew animation...
  this.skewLetters(textElement);
  const skewAnim_new = {
    id: rztTextToSkewId,
    element: textElement,
    interval: setInterval(() => this.skewLetters(textElement), 500),
  };
  this.setState({ runningSkewAnims: [...runningSkewAnims, skewAnim_new] });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_text_mouseEnter;
