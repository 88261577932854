// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  noCardsInView: ["skewing text on eventbus call", "no cards in view"],
  allCardsInView: ["skewing text on eventbus call", "all cards in view"],
  noTextEl: ["skewing text on eventbus call", "no valid DOM element found"],
  noSkewAnim: ["stopping skew anim. for card", "no skewAnim found for card"],
  animAlreadyRunning: ["skewing text on eventbus call", "animation already running"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_FrontPageScroller_stCh_cardStates(ARGS) {
  // Stop, if module instance is turned off or not on mobile...
  if (this.state.mode == "off" || !this.state.is_mobile) return;

  // Setup...
  const { runningSkewAnims } = this.state;
  const { cardStates, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  //////////////////////////////////////
  // Card text skewing anim. toggling //
  //////////////////////////////////////

  // Start skewing anim. for cards in view...
  const cards_inView = cardStates.filter((cardState) => cardState.is_inView);
  if (cards_inView.length > 0) {
    cards_inView.forEach((card_inView) => {
      const { el: card_inView_el } = card_inView;
      const { id: card_inView_id } = card_inView_el.dataset;

      // Stop, if animation for card is already running...
      const skewAnim_card = runningSkewAnims.find((anim) => anim.id == card_inView_id);
      if (skewAnim_card) {
        this.logger("info", loggerMessages.animAlreadyRunning, "warning", { inline: true });
        return;
      }

      // Find text element w/ matching ID...
      const queryString = `[data-rzt-text-to-skew='uninitialized'][data-rzt-text-is-mobile='true'][data-rzt-text-to-skew-id='${card_inView_id}']`;
      const textElement = document.querySelector(queryString);

      if (!textElement) {
        this.logger("info", loggerMessages.noTextEl, "warning", { inline: true });
        return;
      }

      // Start skew animation...
      this.skewLetters(textElement);
      const skewAnim_new = {
        id: card_inView_id,
        element: textElement,
        interval: setInterval(() => this.skewLetters(textElement), 500),
      };
      this.setState({ runningSkewAnims: [...runningSkewAnims, skewAnim_new] });
    });
  } else this.logger("info", loggerMessages.noCardsInView, "warning", { inline: true });

  // Stop skewing anim. for cards not in view...
  const cards_notInView = cardStates.filter((cardState) => !cardState.is_inView);
  if (cards_notInView.length > 0) {
    cards_notInView.forEach((card_notInView) => {
      const { el: card_notInView_el } = card_notInView;
      const { id: card_notInView_id } = card_notInView_el.dataset;

      // Stop animation for card...
      const skewAnim_card = runningSkewAnims.find((anim) => anim.id == card_notInView_id);
      if (!skewAnim_card) {
        this.logger("info", loggerMessages.noSkewAnim, "warning", { inline: true });
        return;
      }
      clearInterval(skewAnim_card.interval);

      // Remove stopped animation from state...
      this.setState({ runningSkewAnims: runningSkewAnims.filter((skewAnim) => skewAnim.id != card_notInView_id) });

      // Unskew letters...
      this.unskewLetters(skewAnim_card.element);
    });
  } else this.logger("info", loggerMessages.allCardsInView, "warning", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_FrontPageScroller_stCh_cardStates;
