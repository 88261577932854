// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_ScrollArea_reachedBottom(ARGS) {
  // Setup...
  const { fragmPageCloseButtonActive } = this.state;
  const { _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  // Set states...
  // ↳ Toggle visibility of fragm. page close-button
  if (fragmPageCloseButtonActive) this.setState({ fragmPageCloseButtonHidden: false });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
