// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  srcMatchesPlayer: ["preloaded src. matches src. required by player", "init. player with preloaded src."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_Preloader_fileLoad(ARGS) {
  // Setup...
  const { srcWillBePreloaded, src_full, src_full_mobile } = this.options;
  const { orgSrc, URLobject, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  ///////////////////////////////////////////////////////////////////////////
  // If init. src. of this player is to be preloaded, init. player with    //
  // src. received from file-load event if preloaded src. matches required //
  // desktop or mobile src.                                                //
  ///////////////////////////////////////////////////////////////////////////

  // Stop, if src. is not to be preloaded
  // or preloaded src. does not match the required src.
  const preloadedSrcMatches_desktopSrc = src_full === orgSrc;
  const preloadedSrcMatches_mobileSrc = src_full_mobile === orgSrc;
  if (!srcWillBePreloaded || (!preloadedSrcMatches_desktopSrc && !preloadedSrcMatches_mobileSrc)) return;

  this.logger("info", loggerMessages.srcMatchesPlayer, "action");

  // Replace required src. option with preloaded src. & init...
  if (preloadedSrcMatches_desktopSrc) this.options.src_full = URLobject;
  else if (preloadedSrcMatches_mobileSrc) this.options.src_full_mobile = URLobject;
  this.init_source();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_Preloader_fileLoad;
