// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ———————————————————————————————n—————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../baseUtilities/validate/validate_refEl";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_link_mouseEnter from "../eventHandlers/eh_link_mouseEnter";
import eh_link_mouseLeave from "../eventHandlers/eh_link_mouseLeave";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  noValidDOMEl: ["init. links", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_links() {
  // Setup...
  this.logger("init", ["links"], "action", { inline: true });

  // Find applicable link elements (data-rzt-link)...
  const linkElements = document.querySelectorAll("[data-rzt-link='uninitialized']");
  if (!linkElements || linkElements.length < 1) {
    this.logger("warning", loggerMsgs.noValidDOMEl, "warning", { inline: true });
    return;
  }

  // Listen to mouse events on links...
  linkElements.forEach((el) => {
    if (!validate_refEL(el)) return;
    el.addEventListener("mouseenter", eh_link_mouseEnter.bind(this, el));
    el.addEventListener("mouseleave", eh_link_mouseLeave.bind(this, el));
    el.setAttribute("data-rzt-link", "initialized");
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_links;
