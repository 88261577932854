// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_linkStates(CHANGES) {
  // Setup...
  const { links } = this.ref;
  const { linkStates } = CHANGES;
  this.logger("state-change", [`link states:`, linkStates]);

  // Updt. link elements...
  // ↳ is-active attr./styles
  // ↳ is-active attr./styles on section headline
  links.forEach((link) => {
    const { targetId } = link.dataset;
    const { is_inView } = linkStates.find((linkState) => linkState.targetId === targetId);

    // Updt. link el...
    link.setAttribute("data-is-active", is_inView);

    // Updt. headline el. corresponding to active link...
    const scrollSection = document.getElementById(targetId) || document.querySelector(`[data-scroll-id="${targetId}"]`);
    if (!scrollSection) return;
    const scrollSectionHeadline =
      scrollSection.querySelector(".Heading") || scrollSection.querySelector("[data-role='headline']");
    if (!scrollSectionHeadline) return;
    scrollSectionHeadline.setAttribute("data-is-active", is_inView);
  });

  // Notify other components...
  eventbus.emit("ScrollMenu_stCh_linkStates", { linkStates });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
