// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_soundButton_click from "../eventHandlers/eh_soundButton_click";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_soundButton(BUTTONELEMENT = this.ref.soundButton) {
  // Setup...
  this.logger("init", ["sound button"], "action", { inline: true });

  // Stop, if no playbutton el. available
  if (!BUTTONELEMENT) {
    this.logger("info", ["no valid soundButton element available"], "warning");
    return false;
  }

  // Listen for click & mouse interactions on play-button...
  BUTTONELEMENT.addEventListener("click", eh_soundButton_click.bind(this));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_soundButton;
