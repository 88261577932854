// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * @description Function to handle changes to the mode state.
 *              The mode may be changed to the following possible states:
 *
 *              - "active"   → the component element is visible
 *              - "inactive" → the component element is not visible
 *
 *              The mode state at the occurence of the following events:
 *
 *              - "swup_link_click"      → "active" when navigating to a fragment page,
 *                                       → "inactive" when navigating to regular page
 *
 *             - "swup_history_popstate" → "active" when navigating to a fragment page,
 *                                       → "inactive" when navigating to regular page
 *
 * @param {Object} CHANGES - Object containing the changes to the mode state.
 **/

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_mode(CHANGES) {
  // Setup...
  const opts = this.options;
  const { mode } = CHANGES;
  this.logger("state-change", [`mode: ${mode}`], "default", { inline: true });

  // Show/Hide component element...
  this.element.setAttribute("data-is-active", mode === "active");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_mode;
