// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ————————————————————————————————————————— PARENT CLASS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import BaseFragmentPage from "../../component-base-fragment-page/js/BaseFragmentPage";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_ScrollArea_scroll from "./eventbusHandlers/ebh_ScrollArea_scroll.js";

import stChH_closeAnimDirection from "./stateChangeHandlers/stChH_closeAnimDirection.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = [];

// Default values for manually extracted options
// (see constructor, in case specific option has not been provided
// in comp. config.).

const defaultOptions = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class FragmentPage extends BaseFragmentPage {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      ...super.ref,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "FragmentPage",
      ...autoOptions,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    // ...

    //////////// Init. f() /////////////
    ////////////////////////////////////

    // ...

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_ScrollArea_scroll = ebh_ScrollArea_scroll.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_closeAnimDirection = stChH_closeAnimDirection.bind(this);

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    super.mount();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    super.unmount();

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("ScrollArea_scroll", this.ebl_ScrollArea_scroll);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    super.init();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    super.init_states();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    super.init_eventbus();

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("ScrollArea_scroll", this.ebl_ScrollArea_scroll);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    super.stateChange(CHANGES);

    //////// closeAnimDirection ////////
    ////////////////////////////////////

    if ("closeAnimDirection" in CHANGES) this.stChL_closeAnimDirection(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default FragmentPage;
