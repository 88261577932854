// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function validate_eventHandler(HANDLERNAME, HANDLERREGISTRY) {
  const handlerRegistryExists = HANDLERREGISTRY !== undefined && HANDLERREGISTRY !== null;
  const handler = handlerRegistryExists && HANDLERREGISTRY[HANDLERNAME];
  const handlerExists = handler !== undefined && handler !== null;
  const handlerIsFunction = handlerExists && typeof HANDLERREGISTRY[HANDLERNAME] === "function";
  return handlerIsFunction;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Variables
let is_scrolledTo_top_prev = true;
let is_scrolledTo_bottom_prev = false;

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_scroll(EVENTDATA) {
  // Setup...
  const { name } = this.options;
  const customEventHandlers = this.customEventHandlers;
  const { direction } = EVENTDATA;
  const has_customScrollEventHandler = validate_eventHandler("scroll", customEventHandlers);
  this.logger("event", ["lenis instance scroll"], "event", { inline: true });

  // Get scroll position...
  const scrollPos_curr = this.element.scrollTop;
  const scrollPos_max = this.element.scrollHeight - this.element.clientHeight;

  //////////////////////////////////
  // Reaching bottom/top handling //
  //////////////////////////////////

  // Determ. if scrolled to bottom/top (within last/first 10% of scroll area)...
  const is_scrolledTo_bottom = scrollPos_curr >= scrollPos_max * 0.9;
  const is_scrolledTo_top = scrollPos_curr <= 100;

  // Notify other components when reaching bottom/top...
  if (is_scrolledTo_bottom && !is_scrolledTo_bottom_prev) eventbus.emit(`${name}_reachedBottom`);
  if (is_scrolledTo_top && !is_scrolledTo_top_prev) eventbus.emit(`${name}_reachedTop`);

  // Updt. variables tracking prev. scrolled-to-bottom/top state...
  if (is_scrolledTo_bottom != is_scrolledTo_bottom_prev) is_scrolledTo_bottom_prev = is_scrolledTo_bottom;
  if (is_scrolledTo_top != is_scrolledTo_top_prev) is_scrolledTo_top_prev = is_scrolledTo_top;

  //////////////////////////////////
  //////////////////////////////////

  // Determ. scroll direction...
  const scrollDir = direction === 1 ? "down" : "up";

  // Determ. scroll velocity...
  const scrollVelocity_raw = EVENTDATA.velocity;
  const scrollVelocity_max = 100;
  const scrollVelocity_toUse = scrollVelocity_raw / scrollVelocity_max < 1 ? scrollVelocity_raw / scrollVelocity_max : 1;

  // Notify other components...
  const args = {
    scrollProgress: EVENTDATA.progress,
    direction: scrollDir,
    scrollVelocity: scrollVelocity_toUse,
    is_scrolledTo_bottom: is_scrolledTo_bottom,
    is_scrolledTo_top: is_scrolledTo_top,
  };
  eventbus.emit(`${name}_scroll`, args);

  // Store scroll pos. in state...
  this.setState({ scrollPos: scrollPos_curr });

  // Run custom scroll handler...
  if (!has_customScrollEventHandler) return;
  customEventHandlers.scroll(EVENTDATA);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_scroll;
