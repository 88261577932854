// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import set_contentBgSize from "./utilities/set_contentBgSize.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_buttons from "./init/init_buttons.js";
import init_content from "./init/init_content.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_FrontPageScroller_footerSectionInViewChange from "./eventbusHandlers/ebh_FrontPageScroller_footerSectionInViewChange.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_contentBgSize from "./stateChangeHandlers/stChH_contentBgSize.js";
import stChH_contentVisibility from "./stateChangeHandlers/stChH_contentVisibility.js";
import stChH_notifBadgeAnim from "./stateChangeHandlers/stChH_notifBadgeAnim.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class CalendarWidget extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      openButton: null,
      closeButton: null,
      contentWrapper: null,
      contentBg: null,
      eventList: null,
      eventListItems: [],
      notificationBadge: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "CalendarWidget",
      run_withLogs: "defaultValue",
      logStyles: "defaultValue",
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.set_contentBgSize = set_contentBgSize.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_buttons = init_buttons.bind(this);
    this.init_content = init_content.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);
    this.ebl_FrontPageScroller_footerSectionInViewChange = ebh_FrontPageScroller_footerSectionInViewChange.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_mode = stChH_mode.bind(this);
    this.stChL_contentBgSize = stChH_contentBgSize.bind(this);
    this.stChL_contentVisibility = stChH_contentVisibility.bind(this);
    this.stChL_notifBadgeAnim = stChH_notifBadgeAnim.bind(this);

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("FrontPageScroller_footerSectionInViewChange", this.ebl_FrontPageScroller_footerSectionInViewChange);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_states();
    this.init_buttons();
    this.init_content();
    this.init_eventbus();
    //////
    this.setState({ mode: "active" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({
      mode: "initialising",
      is_mobile: window.innerWidth < 640,
      contentVisibility: "hidden",
      notifBadgeAnim: { direction: "right" },
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("FrontPageScroller_footerSectionInViewChange", this.ebl_FrontPageScroller_footerSectionInViewChange);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    /////////////// mode ///////////////
    ////////////////////////////////////

    if ("mode" in CHANGES) this.stChL_mode(CHANGES);

    ////////// contentBgSize //////////
    ////////////////////////////////////

    if ("contentBgSize" in CHANGES) this.stChL_contentBgSize(CHANGES);

    //////// contentVisibility ////////
    ////////////////////////////////////

    if ("contentVisibility" in CHANGES) this.stChL_contentVisibility(CHANGES);

    ////////// notifBadgeAnim //////////
    ////////////////////////////////////

    if ("notifBadgeAnim" in CHANGES) this.stChL_notifBadgeAnim(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default CalendarWidget;
