// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { SVG, Timeline } from "@svgdotjs/svg.js";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function init_logoAnimation() {
  // Setup...
  const { logoShape } = this.ref;
  this.logger("init", ["logo animation"], "action");
  if (!logoShape) return this.logger("error", ["logoShape not found"], "error", { inline: true });

  ///////////////////////////////////////////
  // Start recurring logo »flip« animation //
  ///////////////////////////////////////////

  // Create timeline...
  const timeline = new Timeline().persist(true);

  // Add shape to timeline (assign timeline to shape)...
  const logoShape_animatable = SVG(logoShape).timeline(timeline);

  // Add & loop animation (flip/mirroring along x-axis)...
  const animationDelay = 4000;
  const animation = logoShape_animatable.animate({ duration: 1000, delay: animationDelay }).ease("<>").transform({ flip: "x" });
  animation.loop(Infinity, true, animationDelay);

  return animation;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default init_logoAnimation;
