// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_closeButton_click(NOTIFICATIONEL, EVENTDATA) {
  // Setup...
  const { notificationStates } = this.state;
  this.logger("event", ["[message]"], "event", { inline: true });

  // Toggle clicked notification's visibility...
  const notificationId = NOTIFICATIONEL.dataset.id;
  const notificationStates_new = notificationStates.map((notifState) => {
    return notifState.id === notificationId ? { ...notifState, visibility: "hidden" } : notifState;
  });
  this.setState({ notificationStates: notificationStates_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_closeButton_click;
