// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_Calendar_stCh_activeMonth(ARGS) {
  // Stop, if module instance is turned off...
  if (this.state.mode == "off") return;

  // Setup...
  const { activeMonthID, monthHeadings, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  // Trigger letter skewing on month headings...
  if (activeMonthID !== null) {
    const activeMonthHeading = monthHeadings.find((mh) => mh.dataset.id === activeMonthID);
    if (activeMonthHeading) this.skewLetters(activeMonthHeading.querySelector("span"));
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
