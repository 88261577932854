// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_ScrollArea_scrollDirChange(ARGS) {
  // Setup...
  const { simpleLangSwitchHidden, showSimpleLangContent } = this.state;
  const { direction, is_scrolledTo_bottom, footer_is_inView, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  // Stop, if footer is in view...
  if (footer_is_inView) return;

  // Set states...
  // ↳ If not active (page is not showing simple lang. content), hide simple lang. switch on down-scroll
  // ↳ Toggle visibility of back-to-top button
  // ↳ Toggle visibility of fragm. page close-button
  const goingDown = direction === "down";
  this.setState({
    simpleLangSwitchHidden: !showSimpleLangContent && goingDown ? true : simpleLangSwitchHidden,
    backToTopButtonHidden: goingDown ? false : true,
    fragmPageCloseButtonHidden: is_scrolledTo_bottom && goingDown ? false : true,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
