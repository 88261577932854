// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_fragmPageCloseButtonActive(CHANGES) {
  // Setup...
  const { fragmPageCloseButton } = this.ref;
  const { fragmPageCloseButtonActive } = CHANGES;
  this.logger("state-change", [`fragm.-page-close-button active: ${fragmPageCloseButtonActive}`], "default", { inline: true });

  // Disable/Enable fragm. page close-button...
  fragmPageCloseButton.setAttribute("data-is-active", fragmPageCloseButtonActive);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_fragmPageCloseButtonActive;
