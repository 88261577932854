// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_FrontPageScroller_card_mouseEnter(ARGS) {
  // Stop, if module instance is turned off...
  if (this.state.mode == "off") return;

  // Setup...
  const { runningSkewAnims } = this.state;
  const { cardId, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  /////////////////////////////////
  // Card text skewing animation //
  /////////////////////////////////

  // Stop, if animation for card is already running...
  const skewAnim_card = runningSkewAnims.find((anim) => anim.id == cardId);
  if (skewAnim_card) {
    this.logger("info", ["skewing text on eventbus call", "animation already running"], "warning", { inline: true });
    return;
  }

  // Find text element w/ matching ID...
  const queryString = `[data-rzt-text-to-skew='uninitialized'][data-rzt-text-to-skew-id='${cardId}']`;
  const textElement = document.querySelector(queryString);
  if (!textElement) {
    this.logger("info", ["skewing text on eventbus call", "no valid DOM element found"], "warning", { inline: true });
    return;
  }

  // Start skew animation...
  this.skewLetters(textElement);
  const skewAnim_new = {
    id: cardId,
    element: textElement,
    interval: setInterval(() => this.skewLetters(textElement), 500),
  };
  this.setState({ runningSkewAnims: [...runningSkewAnims, skewAnim_new] });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_FrontPageScroller_card_mouseEnter;
