// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function skewElement(ELEMENT) {
  if (!ELEMENT || ELEMENT instanceof HTMLElement === false) {
    this.logger("error", ["skewElement", "no valid DOM element provided"], "error", { inline: true });
    return;
  }

  // Skew element by random degree...
  const { linkElementSkewing } = this.options;
  const { maxDegree, minDegree } = linkElementSkewing;
  const randomDegree = Math.random() < 0.5 ? maxDegree : minDegree;
  updt_styleProp(ELEMENT, "transform", `skew(${randomDegree}deg, 0deg) translateX(0px)`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default skewElement;
