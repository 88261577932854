// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_playback(CHANGES) {
  // Setup...
  const { playback } = CHANGES;
  this.logger("state-change", [`playback: ${playback}`], "default", { inline: true });

  // Toggle playback of video-js instance...
  const playerInstance = this.modules.videojs.instance;
  if (!playerInstance) return;
  if (playback === "play") playerInstance.play();
  else playerInstance.pause();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_playback;
