// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  stateChange: (CHANGE) => [`active month: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeMonth(CHANGES) {
  // Setup...
  const { monthHeadings } = this.ref;
  const { activeMonth } = CHANGES;
  this.logger("state-change", loggerMessages.stateChange(activeMonth), "default", { inline: true });

  // Toggle active attr. on month headings...
  monthHeadings.forEach((mh, i) => mh.setAttribute("data-is-active", i === activeMonth));

  // Notify other components...
  if (activeMonth === undefined) return;
  const activeMonthID = monthHeadings[activeMonth].dataset.id;
  const args = { activeMonthID, monthHeadings };
  eventbus.emit("Calendar_stCh_activeMonth", args);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
