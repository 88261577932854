// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  eventStart: ["mouse entered event-link"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_eventLink_mouseEnter(LINKEL, EVENTDATA) {
  if (this.state.is_mobile) return;

  // Setup...
  const viewportWidth = window.innerWidth;
  const linkData = LINKEL.dataset;
  this.logger("event", loggerMessages.eventStart, "event", { inline: true });

  // Updt. active event state...
  this.setState({ activeEvent: linkData.id });

  // Updt. backgr. img. URL (choose from src. set)...
  const eventImgData = linkData.img;
  if (eventImgData) {
    // Convert string sizes to integers...
    const imgSrcSet = JSON.parse(eventImgData).map((src) => {
      return { size: src.size === "full" ? "full" : parseInt(src.size), url: src.url };
    });

    // Pick src. closet to curr. viewp. width...
    const srcToUse = imgSrcSet.reduce((accumulator, currentObject) =>
      Math.abs(viewportWidth - currentObject.size) < Math.abs(viewportWidth - accumulator.size) ? currentObject : accumulator
    );

    // Updt. state...
    this.setState({ bgImgUrl: srcToUse.url });
  } else this.setState({ bgImgUrl: "null" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
