// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_buttons from "./init/init_buttons.js";
import init_links from "./init/init_links.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_document_click from "./eventbusHandlers/ebh_document_click.js";
import ebh_ScrollArea_scroll from "./eventbusHandlers/ebh_ScrollArea_scroll.js";
import ebh_ScrollArea_footerInViewChange from "./eventbusHandlers/ebh_ScrollArea_footerInViewChange.js";
import ebh_Nav_stCh_showSimpleLangContent from "./eventbusHandlers/ebh_Nav_stCh_showSimpleLangContent.js";

import stChH_hidden from "./stateChangeHandlers/stChH_hidden.js";
import stChH_linkStates from "./stateChangeHandlers/stChH_linkStates.js";
import stChH_scrolledToTop from "./stateChangeHandlers/stChH_scrolledToTop.js";
import stChH_contentVisibility from "./stateChangeHandlers/stChH_contentVisibility.js";
import stChH_scrollProgress from "./stateChangeHandlers/stChH_scrollProgress.js";
import stChH_footerInView from "./stateChangeHandlers/stChH_footerInView.js";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class ScrollMenu extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      openButton: null,
      contentWrapper: null,
      links: [],
      scrollProgressIndicator: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    this.options = {
      name: "ScrollMenu",
      run_withLogs: "defaultValue",
      logStyles: "defaultValue",
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featInit.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_buttons = init_buttons.bind(this);
    this.init_links = init_links.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);
    this.ebl_document_click = ebh_document_click.bind(this);
    this.ebl_ScrollArea_scroll = ebh_ScrollArea_scroll.bind(this);
    this.ebl_ScrollArea_footerInViewChange = ebh_ScrollArea_footerInViewChange.bind(this);
    this.ebl_Nav_stCh_showSimpleLangContent = ebh_Nav_stCh_showSimpleLangContent.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_contentVisibility = stChH_contentVisibility.bind(this);
    this.stChL_hidden = stChH_hidden.bind(this);
    this.stChL_linkStates = stChH_linkStates.bind(this);
    this.stChL_scrolledToTop = stChH_scrolledToTop.bind(this);
    this.stChL_scrollProgress = stChH_scrollProgress.bind(this);
    this.stChL_footerInView = stChH_footerInView.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("document_click", this.ebl_document_click);
    eventbus.off("ScrollArea_scroll", this.ebl_ScrollArea_scroll);
    eventbus.off("ScrollArea_footerInViewChange", this.ebl_ScrollArea_footerInViewChange);
    eventbus.off("Nav_stCh_showSimpleLangContent", this.ebl_Nav_stCh_showSimpleLangContent);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_buttons();
    this.init_links();
    this.init_states();
    this.init_eventbus();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    // Setup...
    this.logger("init", ["states"], "action", { inline: true });

    // Set init. states...
    const is_mobile = window.innerWidth < 640;
    this.setState({ is_mobile, contentVisibility: is_mobile ? "hidden" : "visible" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("document_click", this.ebl_document_click);
    eventbus.on("ScrollArea_scroll", this.ebl_ScrollArea_scroll);
    eventbus.on("ScrollArea_footerInViewChange", this.ebl_ScrollArea_footerInViewChange);
    eventbus.on("Nav_stCh_showSimpleLangContent", this.ebl_Nav_stCh_showSimpleLangContent);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    //////// contentVisibility ////////
    ////////////////////////////////////

    if ("contentVisibility" in CHANGES) this.stChL_contentVisibility(CHANGES);

    ////////////// hidden //////////////
    ////////////////////////////////////

    if ("hidden" in CHANGES) this.stChL_hidden(CHANGES);

    //////////// linkStates ////////////
    ////////////////////////////////////

    if ("linkStates" in CHANGES) this.stChL_linkStates(CHANGES);

    ////////// scrolledToTop //////////
    ////////////////////////////////////

    if ("scrolledToTop" in CHANGES) this.stChL_scrolledToTop(CHANGES);

    ////////// scrollProgress //////////
    ////////////////////////////////////

    if ("scrollProgress" in CHANGES) this.stChL_scrollProgress(CHANGES);

    /////////// footerInView ///////////
    ////////////////////////////////////

    if ("footerInView" in CHANGES) this.stChL_footerInView(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ScrollMenu;
