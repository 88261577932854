// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function eh_button_click(BUTTONEL, EVENTDATA) {
  // Setup...
  const flickityInstance = this.modules.flickity.instance;
  this.logger("event", ["slider button clicked"], "event", { inline: true });

  // [action]...
  const direction = BUTTONEL.dataset.direction;
  if (direction === ">") flickityInstance.next();
  else if (direction === "<") flickityInstance.previous();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default eh_button_click;
