// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../../../app/baseUtilities/validate/validate_refEl";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_showSimpleLangContent(CHANGES) {
  // Setup...
  const { simpleLangSwitch } = this.ref;
  const { showSimpleLangContent } = CHANGES;
  this.logger("state-change", [`showing simple lang. content: ${showSimpleLangContent}`], "default", { inline: true });

  // Toggle active/inactive styles on simple lang. switch...
  if (validate_refEL(simpleLangSwitch)) simpleLangSwitch.setAttribute("data-is-active", showSimpleLangContent);

  // Notify other components...
  // ↳ App → toggle global show-simple-lang-content attr. + set global showSimpleLangContent state
  eventbus.emit("Nav_stCh_showSimpleLangContent", { showSimpleLangContent });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
