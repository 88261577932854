// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_loadingCover(CHANGES) {
  // Setup...
  const { loadingCover: loadingCoverState } = CHANGES;
  this.logger("state-change", [`loading cover:`, loadingCoverState]);

  // Visibility //
  if ("visibility" in loadingCoverState) {
    const { loadingCover } = this.ref;
    const { loadingCoverFadeOutDelay } = this.options;
    const { visibility } = loadingCoverState;
    if (!loadingCover || loadingCover instanceof HTMLElement === false) return;
    this.logger("state-change", [`loading cover visibility: ${visibility}`], "default", { inline: true });

    // Toggle visibility of loading cover el...
    if (visibility === "hidden") {
      setTimeout(() => loadingCover.setAttribute("data-is-hidden", true), loadingCoverFadeOutDelay);
    } else {
      // Temp. disable CSS opacity transition of loading cover el...
      loadingCover.style.transition = "none";

      // Show loading cover el...
      loadingCover.setAttribute("data-is-hidden", false);

      // Re-enable CSS opacity transition of loading cover el. after a short delay...
      loadingCover.style.transition = null;
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_loadingCover;
