// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_cards() {
  // Setup...
  const { cards } = this.ref;
  this.logger("init", ["cards"], "action", { inline: true });

  // Guard...
  if (!cards || cards.every((card) => card instanceof HTMLElement === false)) {
    this.logger("error", ["init. cards", "no valid DOM elements provided"], "error", { inline: true });
    return;
  }

  // Set init. card states...
  const cardStates = cards.map((card) => {
    const { top, bottom } = card.getBoundingClientRect();
    const is_inView = top < window.innerHeight && bottom > 0;
    return { el: card, is_inView: is_inView };
  });

  this.setState({ cardStates });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
