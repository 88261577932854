// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_link_click from "../eventHandlers/eh_link_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  noValidDOMEl: ["init. menuBtn", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_links() {
  // Setup...
  const { links } = this.ref;
  this.logger("init", ["links"], "action", { inline: true });

  // Guard...
  if (links.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on links...
  links.forEach((link) => link.addEventListener("click", eh_link_click.bind(this, { linkEl: link })));

  // Set init. link states...
  const linkStates = links.map((link) => {
    const { targetId, isActive } = link.dataset;
    return { targetId, is_inView: isActive === "true" };
  });
  this.setState({ linkStates });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
