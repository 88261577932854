// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import check_mediaEnabledGlobally from "../../../../../app/baseUtilities/check/check_mediaEnabledGlobally";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_FrontPageScroller_card_mouseEnter(ARGS) {
  // Stop, if media is disabled globally...
  if (!check_mediaEnabledGlobally()) return;

  // Setup...
  const { id: componentID, name: componentType } = this.options;
  const { _name, cardType, cardId } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // If card corresponds to component (ID & type match),
  // play video, otherwise pause...
  const cardMatchesComp = cardType === componentType && cardId === componentID;
  this.setState({ playback: cardMatchesComp ? "play" : "pause" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_FrontPageScroller_card_mouseEnter;
