// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_doc_mouseMove(ARGS) {
  // Setup...
  const { mediaToggleEyeball } = this.ref;
  const { _name, eventData } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  // Set media toggle eyeball rotation...
  const { clientX, clientY } = eventData;
  const ryeballRect = mediaToggleEyeball.getBoundingClientRect();
  const x = ryeballRect.left + ryeballRect.width / 2;
  const y = ryeballRect.top + ryeballRect.height / 2;
  const radian = Math.atan2(clientX - x, clientY - y);
  const rotate = radian * (180 / Math.PI) * -1 + 270;
  mediaToggleEyeball.style.setProperty("--rotation", `${rotate}deg`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
