// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function stChH_backToTopButtonHidden(CHANGES) {
  // Setup...
  const { backToTopButton, mediaToggle, fragmPageCloseButton } = this.ref;
  const { backToTopButtonHidden } = CHANGES;
  this.logger("state-change", [`backToTopButtonHidden: ${backToTopButtonHidden}`], "default", { inline: true });

  // Show/hide back-to-top button element...
  backToTopButton.setAttribute("data-is-hidden", backToTopButtonHidden);

  // Updt. corresponding attr. on media toggle & fragm. page close button...
  const buttons = [mediaToggle, fragmPageCloseButton];
  buttons.forEach((el) => el.setAttribute("data-back-to-top-btn-is-visible", !backToTopButtonHidden));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default stChH_backToTopButtonHidden;
